import { authReducer } from './auth.reducer';
import * as actions from './auth.actions';
import sagas from './auth.sagas';
import * as selector from './auth.selector';

export default {
  reducer: authReducer,
  actions,
  sagas,
  selector,
};
