export const invoiceStatus = {
  billed: 'BILLED',
  pending: 'PENDING',
};

export const braInvoiceStatus = {
  billed: 'BILLED',
  pending: 'PENDING',
  void: 'VOID',
};

export const paymentStatus = {
  pending: 'PENDING',
  overDue: 'OVERDUE',
  due: 'DUE',
  paid: 'PAID',
  partiallyPaid: 'PARTIALLY PAID',
  paidCreditNote: 'PAID - CREDIT NOTE',
};

export const usUkPaymentStatus = {
  pending: 'PENDING',
  overDue: 'OVERDUE',
  due: 'DUE',
  paid: 'PAID',
};

export const argPaymentStatus = {
  due: 'DUE',
  paid: 'PAID',
  paidCreditNote: 'PAID - CREDIT NOTE',
  partiallyPaid: 'PARTIALLY PAID',
  overDue: 'OVERDUE',
};

export const braPaymentStatus = {
  due: 'DUE',
  partiallyPaid: 'PARTIALLY PAID',
  paid: 'PAID',
  overDue: 'OVERDUE',
};

export const emailTrackingStatus = {
  pending: 'PENDING',
  delivered: 'DELIVERED',
  opened: 'OPENED',
};

export const csvExportType = {
  regular: 'REGULAR_CSV',
  xero: 'XERO_FORMAT_CSV',
  colppy: 'COLPPY_FORMAT_CSV',
  consolidated: 'CONSOLIDATED_FORMAT_CSV',
};
