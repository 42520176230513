import { paymentSettingsTypesReducer } from './paymentSettingsTypes.reducer';
import * as actions from './paymentSettingsTypes.actions';
import sagas from './paymentSettingsTypes.sagas';
import * as selector from './paymentSettingsTypes.selector';

export default {
  reducer: paymentSettingsTypesReducer,
  actions,
  sagas,
  selector,
};
