import { takeLatest, put, call } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import { Types, fetchAllAdAccountsTypes } from './adAccountTypes.actions';

const BASE_ENDPOINT = '/adAccountTypes';

const findAllRequest = async () => api.get(BASE_ENDPOINT);

function* fetchAdAccountTypes() {
  let result;

  try {
    result = yield call(findAllRequest);

    if (result && result.data) {
      return yield put(fetchAllAdAccountsTypes.success(result.data));
    }
    return yield put(fetchAllAdAccountsTypes.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(fetchAllAdAccountsTypes.failure(error));
    }
    return yield put(fetchAllAdAccountsTypes.failure(message));
  }
}

export default [
  takeLatest(Types.AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_REQUEST, fetchAdAccountTypes),
];
