// Types
export const Types = {
  COUNTRIES_REQUEST: 'COUNTRIES_REQUEST',
  COUNTRIES_REQUEST_SUCCESS: 'COUNTRIES_REQUEST_SUCCESS',
  COUNTRIES_REQUEST_FAILURE: 'COUNTRIES_REQUEST_FAILURE',
};

// Actions
export const countries = {
  request: () => ({
    type: Types.COUNTRIES_REQUEST,
  }),
  success: payload => ({
    type: Types.COUNTRIES_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.COUNTRIES_REQUEST_FAILURE,
    payload,
  }),
};
