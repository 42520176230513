export const Types = {
  AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_REQUEST: 'AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_REQUEST',
  AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_REQUEST_SUCCES:
   'AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_REQUEST_SUCCES',
  AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_FAILURE: 'AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_FAILURE',
};

// Actions
export const fetchAllAdAccountsTypes = {
  request: () => ({
    type: Types.AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_REQUEST,
  }),
  success: payload => ({
    type: Types.AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_REQUEST_SUCCES,
    payload,
  }),
  failure: payload => ({
    type: Types.AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_FAILURE,
    payload,
  }),
};
