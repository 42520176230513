// Types
export const Types = {
  TAXRATENAMES_REQUEST: 'TAXRATENAMES_REQUEST',
  TAXRATENAMES_REQUEST_SUCCESS: 'TAXRATENAMES_REQUEST_SUCCESS',
  TAXRATENAMES_REQUEST_FAILURE: 'TAXRATENAMES_REQUEST_FAILURE',
};

// Actions
export const taxRateNames = {
  request: () => ({
    type: Types.TAXRATENAMES_REQUEST,
  }),
  success: payload => ({
    type: Types.TAXRATENAMES_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.TAXRATENAMES_REQUEST_FAILURE,
    payload,
  }),
};
