export const Messages = {
// Advertiser
  ADVERTISER_FETCH_ADVERTISERS_ERROR: 'An error ocurred trying to fetch advertiser data from Jampp servers',
  // Billing Center
  BILLING_CENTER_WITH_ADACCOUNT: "The Billing Center @name cannot be removed as it's still used by active Ad Account's",
  DELETE_BILLING_CENTER_SUCCESS: 'The Billing Center @name has been removed successfully',
  DELETE_BILLING_CENTER_ERROR: 'An error ocurred and the Billing Center has not been removed successfully',
  CREATE_BILLING_CENTER_SUCCESS: 'The Billing Center @name has been created successfully',
  CREATE_BILLING_CENTER_ERROR: 'An error ocurred and the Billing Center @name has not been created successfully',
  EDIT_BILLING_CENTER_SUCCESS: 'The Billing Center @name has been updated successfully',
  EDIT_BILLING_CENTER_ERROR: 'An error ocurred and the Billing Center @name has not been updated successfully',
  // Ad Account
  FETCH_ADACCOUNT_ERRROR: 'An error ocurred trying to fetch Ad account data',
  CREATE_ADACCOUNT_SUCCESS: 'The Ad Account @name has been created successfully',
  CREATE_ADACCOUNT_ERROR: 'An error ocurred and the Ad Account has not been created successfully',
  CREATE_AD_ACCOUNT_MISSING_BILLING_CENTER:
  'You first need to create a Billing Center in order to finish the Ad account creation',
  CREATE_AD_ACCOUNT_MISSING_CURRENCY_MATCHING_BILLING_CENTER: 'You need to create a Billing Center whose '
   + 'currency matches the selected ad account currency to be able to finish the Ad account creation',
  DELETE_AD_ACCOUNT_SUCCESS: 'The Ad Account @name has been removed successfully',
  DELETE_AD_ACCOUNT_ERROR: 'An error ocurred and the Ad Account @name has not been removed successfully',
  EDIT_AD_ACCOUNT_SUCCESS: 'The Ad Account @name has been updated successfully',
  EDIT_AD_ACCOUNT_ERROR: 'An error ocurred and the Ad Account @name has not been updated successfully',
  // Credit note
  CREATE_CREDIT_NOTE_SUCCESS: 'The new Credit Note has been sent successfully',
  CREATE_CREDIT_NOTE_ERROR: 'An error ocurred and the new Credit Note hasn\'t been sent, please try again',
  CREDIT_NOTE_EDIT_SUCCESS: 'The Credit Note has been updated successfully',
  CREDIT_NOTE_EDIT_ERROR: 'An error ocurred and the Credit Note hasn\'t been updated, please try again',
  CREDIT_NOTE_DELETE_SUCCESS: 'The Credit Note has been removed successfully',
  CREDIT_NOTE_DELETE_ERROR: 'An error ocurred and the Credit Note hasn\'t been removed, please try again',
  // Invoice
  FETCH_INVOICE_ERROR: 'An error ocurred trying to fetch invoice data',
  EDIT_INVOICE_ERROR: 'An error ocurred trying to update invoice data',
  // CSV
  DOWNLOAD_CSV_ERROR: 'An error ocurred trying to generate or download a CSV',
  // Payment Method
  CREATE_PAYMENT_METHOD_SUCCESS: 'The Payment Method has been created successfully',
  CREATE_PAYMENT_METHOD_ERROR: 'An error ocurred and the Payment Method has not been created successfully',
  // Adjustments
  CREATE_ADJUSTMENT_SUCCESS: 'The Adjustment has been created successfully',
  CREATE_ADJUSTMENT_ERROR: 'An error ocurred and the adjustment has not been created successfully',
  EDIT_ADJUSTMENT_SUCCESS: 'The Adjustment has been updated successfully',
  EDIT_ADJUSTMENT_ERROR: 'An error ocurred and the adjustment has not been updated',
  DELETE_ADJUSTMENT_SUCCESS: 'The Adjustment has been deleted successfully',
  DELETE_ADJUSTMENT_FAILURE: 'An error ocurred and the adjustment has not been deleted successfully',
  EDIT_ADJUSTMENT_ERROR_BILLED: 'You can not edit an Adjustment that has been billed',
  // End User
  END_USER_FETCH_SPEND_DATA_ERROR: 'An error ocurred trying to fetch ad account spend data',
  END_USER_FETCH_INVOICE_DATA_ERROR: 'An error ocurred trying to fetch invoice data ',
};
