// Types
export const Types = {
  END_USER_FETCH_OVERVIEW_INVOICES_REQUEST: 'END_USER_FETCH_OVERVIEW_INVOICES_REQUEST',
  END_USER_FETCH_OVERVIEW_INVOICES_SUCCESS: 'END_USER_FETCH_OVERVIEW_INVOICES_SUCCESS',
  END_USER_FETCH_OVERVIEW_INVOICES_FAILURE: 'END_USER_FETCH_OVERVIEW_INVOICES_FAILURE',
  END_USER_FETCH_OVERVIEW_INVOICES_CLEAR: 'END_USER_FETCH_OVERVIEW_INVOICES_CLEAR',
  END_USER_FETCH_OVERVIEW_SPEND_REQUEST: 'END_USER_FETCH_OVERVIEW_SPEND_REQUEST',
  END_USER_FETCH_OVERVIEW_SPEND_SUCCESS: 'END_USER_FETCH_OVERVIEW_SPEND_SUCCESS',
  END_USER_FETCH_OVERVIEW_SPEND_FAILURE: 'END_USER_FETCH_OVERVIEW_SPEND_FAILURE',
};

// Actions
export const fetchInvoices = {
  request: (advertiserId, adAccountId, fromDate, toDate) => ({
    type: Types.END_USER_FETCH_OVERVIEW_INVOICES_REQUEST,
    payload: {
      advertiserId, adAccountId, fromDate, toDate,
    },
  }),
  success: payload => ({
    type: Types.END_USER_FETCH_OVERVIEW_INVOICES_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.END_USER_FETCH_OVERVIEW_INVOICES_FAILURE,
    payload,
  }),
  clear: () => ({
    type: Types.END_USER_FETCH_OVERVIEW_INVOICES_CLEAR,
  }),
};

export const fetchSpend = {
  request: (advertiserId, fromDate, toDate) => ({
    type: Types.END_USER_FETCH_OVERVIEW_SPEND_REQUEST,
    payload: { advertiserId, fromDate, toDate },
  }),
  success: payload => ({
    type: Types.END_USER_FETCH_OVERVIEW_SPEND_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.END_USER_FETCH_OVERVIEW_SPEND_FAILURE,
    payload,
  }),
};
