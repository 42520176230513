import { call, takeLatest, put } from 'redux-saga/effects';
import format from 'date-fns/format';
// API
import { api } from 'services/api';

// Types
import { countryCodes } from 'utils/countryCodes';
import {
  Types, campaigns, toggleTest, jamppCampaigns, campaignsCurrentSpend,
} from './campaigns.actions';

const BASE_ENDPOINT = '/campaign';

const fetchList = async () => api.get(`${BASE_ENDPOINT}`);
const fetchQuery = async queryString => {
  const result = await api.get(`${BASE_ENDPOINT}?${queryString}`);
  return result;
};

const putToggleTest = async id => api.put(`${BASE_ENDPOINT}/${id}`);

const getJamppCampaigns = async (advertiserId, adAccountId) => api
  .get(`jampp/campaigns?advertiserId=${advertiserId}&adAccountId=${adAccountId}`);

const getCurrentSpend = async (advertiserId, daily, from, to) => {
  let route = `jampp/campaigns/spend?advertiserId=${advertiserId}`;
  if (daily) {
    route = `${route}&daily=${daily}`;
  }
  if (from) {
    route = `${route}&from=${from}`;
  }
  if (to) {
    route = `${route}&to=${to}`;
  }
  return api.get(route);
};

const currentPeriod = format(new Date(), 'yyyy-MM');

function* CampaignsRequest({ payload }) {
  try {
    let result;
    const query = [];
    let queryString;
    const {
      selectedAdvertisers, selectedAdAccounts, fromDate, toDate, country,
    } = payload;

    if (selectedAdvertisers?.length) query.push(`advertiserId=${selectedAdvertisers.toString()}`);
    if (selectedAdAccounts?.length) query.push(`adAccountId=${selectedAdAccounts.toString()}`);
    if (fromDate && toDate) query.push(`from=${fromDate}&to=${toDate}`);
    if (country) query.push(`country=${country}`);

    if (query.length > 0) {
      queryString = query.join('&');
      result = yield call(fetchQuery, queryString);
    } else {
      result = yield call(fetchList);
    }
    if (result && result.data) {
      yield put(campaigns.success(result.data));
      if (!toDate || (toDate && toDate.startsWith(currentPeriod))) { // Is current period?
        const advertisers = new Set(result.data.map(a => a.AdvertiserId));
        if (advertisers.size > 0) {
          return yield put(campaignsCurrentSpend.request({
            advertiserId: Array.from(advertisers),
            daily: country === countryCodes.bra,
            from: fromDate,
            to: toDate,
          }));
        }
      }
      return true;
    }

    return yield put(campaigns.failure('Unknown Error'));
  } catch (err) {
    if (err.response) {
      const { response: { data } } = err;
      if (data) {
        const error = typeof data === 'string' ? data : data.code;
        return yield put(campaigns.failure(error));
      }
    }
    return yield put(campaigns.failure(err.message));
  }
}

function* ToggleTestCampaign({ payload }) {
  try {
    const { campaignId } = payload;

    const result = yield call(putToggleTest, campaignId);

    if (result && result.data) {
      return yield put(toggleTest.success(result.data));
    }

    return yield put(toggleTest.failure('Unknown Error'));
  } catch (err) {
    if (err.response) {
      const { response: { data } } = err;
      if (data) {
        const error = typeof data === 'string' ? data : data.code;
        return yield put(toggleTest.failure(error));
      }
    }
    return yield put(toggleTest.failure(err.message));
  }
}

function* GetJamppCampaigns({ payload }) {
  try {
    const { advertiserId, adAccountId } = payload;

    const result = yield call(getJamppCampaigns, advertiserId, adAccountId);

    if (result && result.data && result.data.data) {
      return yield put(jamppCampaigns.success(result.data.data));
    }

    return yield put(jamppCampaigns.failure('Unknown Error'));
  } catch (err) {
    if (err.response) {
      const { response: { data } } = err;
      if (data) {
        const error = typeof data === 'string' ? data : data.code;
        return yield put(jamppCampaigns.failure(error));
      }
    }
    return yield put(jamppCampaigns.failure(err.message));
  }
}

function* GetCurrentSpendForCampaign({ payload }) {
  try {
    const {
      advertiserId,
      daily = false,
      from,
      to,
    } = payload;

    const result = yield call(getCurrentSpend, advertiserId, daily, from, to);
    if (result.data && result.data.status === 'success') {
      return yield put(campaignsCurrentSpend.success(result));
    }
    return yield put(campaignsCurrentSpend.failure('Unknown Error'));
  } catch (err) {
    if (err.response) {
      const { response: { data } } = err;
      if (data) {
        const error = typeof data === 'string' ? data : data.code;
        return yield put(campaignsCurrentSpend.failure(error));
      }
    }
    return yield put(campaignsCurrentSpend.failure(err.message || 'Unknown Error'));
  }
}

export default [
  takeLatest(Types.CAMPAIGNS_REQUEST, CampaignsRequest),
  takeLatest(Types.CAMPAIGNS_TOGGLE_TEST_REQUEST, ToggleTestCampaign),
  takeLatest(Types.CAMPAIGNS_FROM_JAMPP_REQUEST, GetJamppCampaigns),
  takeLatest(Types.CAMPAIGNS_CURRENT_SPEND_REQUEST, GetCurrentSpendForCampaign),
];
