import { paymentMethodReducer } from './paymentMethod.reducer';
import * as actions from './paymentMethod.actions';
import sagas from './paymentMethod.sagas';
import * as selector from './paymentMethod.selector';

export default {
  reducer: paymentMethodReducer,
  actions,
  sagas,
  selector,
};
