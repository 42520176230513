import React from 'react';

// Styles
import './index.css';

const Loading = () => (
  <div className="loading-component">
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
export default Loading;
