import { Types } from './brandingThemes.actions';

export const INITIAL_STATE = {
  brandingThemes: [],
  loading: false,
};

export function brandingThemesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.BRANDINGTHEMES_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.BRANDINGTHEMES_REQUEST_SUCCESS: {
      return {
        ...state, loading: false, error: null, brandingThemes: [...action.payload],
      }; }
    case Types.BRANDINGTHEMES_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}
