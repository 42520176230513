import { Types } from './paymentSettings.actions';

export const INITIAL_STATE = {
  loading: true,
  error: null,
  creation: {
    loading: true,
    error: null,
  },
};

export function paymentSettingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PAYMENT_SETTING_FETCH_ALL_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.PAYMENT_SETTING_FETCH_ALL_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case Types.PAYMENT_SETTING_FETCH_ALL_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case Types.PAYMENT_SETTING_CREATE_PAYMENT_SETTING_REQUEST:
      return { ...state, creation: { loading: true, error: null } };
    case Types.PAYMENT_SETTING_CREATE_PAYMENT_SETTING_SUCCESS:
      return { ...state, creation: { ...state.creation, data: action.payload, loading: false } };
    case Types.PAYMENT_SETTING_CREATE_PAYMENT_SETTING_FAILURE:
      return { ...state, creation: { ...state.creation, error: action.payload, loading: false } };
    case Types.PAYMENT_SETTING_CREATE_PAYMENT_SETTING_RESET:
      return { ...state, creation: { ...state.creation, data: null, loading: false } };
    default:
      return state;
  }
}
