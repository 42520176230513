import { campaignsReducer } from './campaigns.reducer';
import * as actions from './campaigns.actions';
import sagas from './campaigns.sagas';
import * as selector from './campaigns.selector';

export default {
  reducer: campaignsReducer,
  actions,
  sagas,
  selector,
};
