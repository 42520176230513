export const Types = {
  CURRENCIES_FETCH_ALL_CURRENCIES_REQUEST: 'CURRENCIES_FETCH_ALL_CURRENCIES_REQUEST',
  CURRENCIES_FETCH_ALL_CURRENCIES_SUCCESS: 'CURRENCIES_FETCH_ALL_CURRENCIES_SUCCESS',
  CURRENCIES_FETCH_ALL_CURRENCIES_FAILURE: 'CURRENCIES_FETCH_ALL_CURRENCIES_FAILURE',
};

// Actions
export const fetchAllCurrencies = {
  request: () => ({
    type: Types.CURRENCIES_FETCH_ALL_CURRENCIES_REQUEST,
  }),
  success: payload => ({
    type: Types.CURRENCIES_FETCH_ALL_CURRENCIES_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CURRENCIES_FETCH_ALL_CURRENCIES_FAILURE,
    payload,
  }),
};
