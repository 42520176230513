import { takeLatest, put, call } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import { Types, fetchAllCurrencies } from './currencies.actions';

const BASE_ENDPOINT = '/currency';

const findAllRequest = async () => api.get(BASE_ENDPOINT);

function* fetchCurrencies() {
  let result;

  try {
    result = yield call(findAllRequest);

    if (result && result.data) {
      return yield put(fetchAllCurrencies.success(result.data));
    }
    return yield put(fetchAllCurrencies.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(fetchAllCurrencies.failure(error));
    }
    return yield put(fetchAllCurrencies.failure(message));
  }
}

export default [
  takeLatest(Types.CURRENCIES_FETCH_ALL_CURRENCIES_REQUEST, fetchCurrencies),
];
