export const selectLoading = state => state.adAccount.list.loading;
export const selectErrors = state => state.adAccount.list.error;
export const adAccountsList = state => state.adAccount.list.data;
export const selectList = state => state.adAccount.list;
export const selectListCurrent = state => state.adAccount.list.currentAdAccount || null;
export const selectTempList = state => state.adAccount.list.temp || [];
export const selectCreationLoading = state => state.adAccount.creation.loading;
export const selectCreationError = state => state.adAccount.creation.error;
export const selectCreation = state => state.adAccount.creation;
export const selectToggleStatus = state => state.adAccount.toggleStatus;
export const selectCurrent = state => state.adAccount.current.data;
export const selectAllFromCurrent = state => state.adAccount.current;
export const selectEdition = state => state.adAccount.edition;
export const selectAdAccountDelete = state => state.adAccount.delete;
