// Types
export const Types = {
  DISCOUNTREASON_REQUEST: 'DISCOUNTREASON_REQUEST',
  DISCOUNTREASON_REQUEST_SUCCESS: 'DISCOUNTREASON_REQUEST_SUCCESS',
  DISCOUNTREASON_REQUEST_FAILURE: 'DISCOUNTREASON_REQUEST_FAILURE',
};

// Actions
export const fetchAll = {
  request: () => ({
    type: Types.DISCOUNTREASON_REQUEST,
  }),
  success: payload => ({
    type: Types.DISCOUNTREASON_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.DISCOUNTREASON_REQUEST_FAILURE,
    payload,
  }),
};
