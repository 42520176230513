// Types
export const Types = {
  EMAILS_REQUEST: 'EMAILS_REQUEST',
  EMAILS_REQUEST_SUCCESS: 'EMAILS_REQUEST_SUCCESS',
  EMAILS_REQUEST_FAILURE: 'EMAILS_REQUEST_FAILURE',

  EMAILS_DELETE_REQUEST: 'EMAILS_DELETE_REQUEST',
  EMAILS_DELETE_SUCCESS: 'EMAILS_DELETE_SUCCESS',
  EMAILS_DELETE_FAILURE: 'EMAILS_DELETE_FAILURE',
  EMAILS_DELETE_RESET: 'EMAILS_DELETE_RESET',
};

// Actions

export const deleteEmails = {
  request: payload => ({
    type: Types.EMAILS_DELETE_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.EMAILS_DELETE_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.EMAILS_DELETE_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.EMAILS_DELETE_RESET,
  }),
};
