export const Types = {
  NOTIFICATION_RESET: 'NOTIFICATION_RESET',
  NOTIFICATION_ERROR_NOTIFICATION: 'NOTIFICATION_ERROR_NOTIFICATION',
};

export const notification = {
  reset: () => ({
    type: Types.NOTIFICATION_RESET,
  }),
  errorNotification: (message, isPermanent) => ({
    type: Types.NOTIFICATION_ERROR_NOTIFICATION,
    payload: {
      message,
      isPermanent: !!isPermanent,
    },
  }),
};
