import { all } from 'redux-saga/effects';

// Saga
import auth from './auth';
import user from './user';

import advertisers from './advertisers';
import countries from './countries';
import states from './states';
import currencies from './currencies';

import adAccount from './adAccount';
import billingCenters from './billingCenters';
import adAccountTypes from './adAccountTypes';
import net from './net';
import taxIdType from './taxIdType';
import taxRateNames from './taxRateNames';
import taxRates from './taxRate';
import jamppBillingOffices from './jamppBillingOffice';
import brandingThemes from './brandingThemes';
import paymentSettings from './paymentSettings';
import paymentSettingsTypes from './paymentSettingsTypes';
import paymentMethods from './paymentMethod';
import emails from './emails';
import campaigns from './campaigns';
import adjustments from './adjustments';
import discountReasons from './discountReason';
import invoice from './invoice';
import creditNotes from './creditNotes';
import endUser from './endUser';

export function* rootSaga() {
  yield all([
    ...auth.sagas,
    ...user.sagas,
    ...advertisers.sagas,
    ...countries.sagas,
    ...states.sagas,
    ...currencies.sagas,
    ...adAccount.sagas,
    ...adAccountTypes.sagas,
    ...billingCenters.sagas,
    ...paymentSettings.sagas,
    ...paymentSettingsTypes.sagas,
    ...paymentMethods.sagas,
    ...net.sagas,
    ...taxIdType.sagas,
    ...taxRateNames.sagas,
    ...taxRates.sagas,
    ...jamppBillingOffices.sagas,
    ...brandingThemes.sagas,
    ...emails.sagas,
    ...campaigns.sagas,
    ...adjustments.sagas,
    ...discountReasons.sagas,
    ...invoice.sagas,
    ...creditNotes.sagas,
    ...endUser.sagas,
  ]);
}
