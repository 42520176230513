import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API || ''}/`,
  withCredentials: true,
});

const JamppURL = process.env.REACT_APP_JAMPP_LOGIN_PATH || 'https://auth-dev.jampp.com/pr101/login';
export const isJamppDomain = window.location.host.includes('jampp.com');

export const LoginPath = isJamppDomain ? JamppURL : '/login';

export function goToLogin() {
  window.location.replace(`${LoginPath}?redirect=${window.location.href}`);
}

api.interceptors.response.use(response => response, async error => {
  if (error.response.status === 401) {
    goToLogin();
  }

  return error;
});
