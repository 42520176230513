import { billingCentersReducer } from './billingCenter.reducer';
import * as actions from './billingCenter.actions';
import sagas from './billingCenter.sagas';
import * as selector from './billingCenter.selector';

export default {
  reducer: billingCentersReducer,
  actions,
  sagas,
  selector,
};
