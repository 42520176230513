import { takeLatest, put, select } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import { Types, currentUser } from './user.actions';

// Selector
import { selectCurrent } from './user.selector';

// Errors
import UserError from './user.error';

const BASE_ENDPOINT = '/user';

function* currentUserRequest({ payload }) {
  let result;
  try {
    const state = yield select();
    const current = selectCurrent(state);
    if (!current) {
      result = yield api.get(`${BASE_ENDPOINT}/current`, payload);
      if (result && result.data) {
        return yield put(currentUser.success(result.data));
      }
      return yield put(currentUser.failure('Unknown Error'));
    }
    return yield put(currentUser.success(current));
  } catch ({ response: { data, status }, message }) {
    let finalMsg = message;
    if (data && data.code) {
      finalMsg = typeof data === 'string' ? data : UserError[data.code] || data.code;
    }
    return yield put(currentUser.failure(finalMsg));
  }
}

export default [
  takeLatest(Types.CURRENT_USER_REQUEST, currentUserRequest),
];
