import { call, takeLatest, put } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import {
  Types, billingCenters, deleteBillingCenter, createBillingCenter, billingCenterById, editBillingCenter,
} from './billingCenter.actions';

// Errors
import BillingCenterError from './billingCenter.error';

const BASE_ENDPOINT = '/billingCenter';

const fetchList = async advertiserId => api.get(`${BASE_ENDPOINT}/advertiserId/${advertiserId}`);
const fetchById = async billingCenterId => api.get(`${BASE_ENDPOINT}/${billingCenterId}`);
const deleteAction = async billingCenterId => api.delete(`${BASE_ENDPOINT}/${billingCenterId}`);
const create = async newBillingCenterData => api.post(`${BASE_ENDPOINT}`, newBillingCenterData);
const edit = async editBillingCenterData => api.patch(`${BASE_ENDPOINT}`, editBillingCenterData);

function* billingCentersRequest({ payload: advertiserId }) {
  try {
    const result = yield call(fetchList, advertiserId);

    if (result && result.data) {
      return yield put(billingCenters.success(result.data));
    }
    return yield put(billingCenters.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(billingCenters.failure(error));
    }
    return yield put(billingCenters.failure(message));
  }
}

function* billingCenterRequestById({ payload: billingCenterId }) {
  try {
    const result = yield call(fetchById, billingCenterId);
    if (result && result.data) {
      return yield put(billingCenterById.success(result.data));
    }
    return yield put(billingCenterById.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(billingCenterById.failure(error));
    }
    return yield put(billingCenterById.failure(message));
  }
}

function* deleteBillingCenterRequest({ payload }) {
  try {
    const result = yield call(deleteAction, payload.id);
    if (result && result.data) {
      return yield put(deleteBillingCenter.success(payload));
    }
    if (result.response && result.response.data && result.response.data.code) {
      return yield put(deleteBillingCenter.failure({ error: result.response.data.code, name: payload.name }));
    }
    return put(deleteBillingCenter.failure('Unknown error'));
  } catch (err) {
    const { response, message } = err;
    if (!response || !response.data) {
      return yield put(deleteBillingCenter.failure(message || 'Unknown error'));
    }
    const error = typeof response.data === 'string' ? response.data : response.data.code;
    return yield put(deleteBillingCenter.failure({ error, name: payload.name }));
  }
}

function* createNewBillingCenter({ payload }) {
  let result;
  try {
    result = yield call(create, payload);
    if (result && result.data) {
      return yield put(createBillingCenter.success(result.data));
    }
    return yield put(createBillingCenter.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : BillingCenterError[data.code] || data.code;
      return yield put(createBillingCenter.failure(error));
    }
    return yield put(createBillingCenter.failure(message));
  }
}

function* editBillingCenterRequest({ payload }) {
  try {
    const result = yield call(edit, payload);
    if (result && result.data) {
      return yield put(editBillingCenter.success(result.data));
    }
    return yield put(editBillingCenter.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : BillingCenterError[data.code] || data.code;
      return yield put(editBillingCenter.failure(error));
    }
    return yield put(editBillingCenter.failure(message));
  }
}

export default [
  takeLatest(Types.BILLING_CENTER_REQUEST, billingCentersRequest),
  takeLatest(Types.BILLING_CENTER_REQUESTBYID, billingCenterRequestById),
  takeLatest(Types.BILLING_CENTER_DELETE_REQUEST, deleteBillingCenterRequest),
  takeLatest(Types.BILLING_CENTER_EDIT_BILLING_CENTER_REQUEST, editBillingCenterRequest),
  takeLatest(Types.BILLING_CENTER_CREATE_BILLING_CENTER_REQUEST, createNewBillingCenter),
];
