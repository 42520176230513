import { paymentSettingsReducer } from './paymentSettings.reducer';
import * as actions from './paymentSettings.actions';
import sagas from './paymentSettings.sagas';
import * as selector from './paymentSettings.selector';
import PaymentSettingError from './paymentSetting.error';

export default {
  reducer: paymentSettingsReducer,
  actions,
  sagas,
  selector,
  error: PaymentSettingError,
};
