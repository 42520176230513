// Types
export const Types = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
  LOGIN_REQUEST_FAILURE: 'LOGIN_REQUEST_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_REQUEST_SUCCESS: 'LOGOUT_REQUEST_SUCCESS',
  LOGOUT_REQUEST_FAILURE: 'LOGOUT_REQUEST_FAILURE',
};

// Actions
export const login = {
  request: payload => ({
    type: Types.LOGIN_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.LOGIN_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.LOGIN_REQUEST_FAILURE,
    payload,
  }),
};

export const logout = {
  request: payload => ({
    type: Types.LOGOUT_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.LOGOUT_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.LOGOUT_REQUEST_FAILURE,
    payload,
  }),
};
