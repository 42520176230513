import { call, takeLatest, put } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import { Types, taxIdTypes } from './taxIdType.actions';

const BASE_ENDPOINT = '/taxIdType';

const fetchList = async () => api.get(`${BASE_ENDPOINT}`);

function* TaxIdTypesRequest() {
  try {
    const result = yield call(fetchList);
    if (result && result.data) {
      return yield put(taxIdTypes.success(result.data));
    }
    return yield put(taxIdTypes.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(taxIdTypes.failure(error));
    }
    return yield put(taxIdTypes.failure(message));
  }
}

export default [
  takeLatest(Types.TAXIDTYPE_REQUEST, TaxIdTypesRequest),
];
