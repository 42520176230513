import { creditNotesReducer } from './creditNotes.reducer';
import * as actions from './creditNotes.actions';
import sagas from './creditNotes.sagas';
import * as selector from './creditNotes.selector';

export default {
  reducer: creditNotesReducer,
  actions,
  sagas,
  selector,
};
