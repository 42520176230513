import { call, takeLatest, put } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import {
  Types, deleteEmails,
} from './emails.actions';

const BASE_ENDPOINT = '/email';

const deleteAction = async emailId => api.delete(`${BASE_ENDPOINT}/${emailId}`);

function* deleteEmailsRequest({ payload }) {
  try {
    const result = yield call(deleteAction, payload);
    if (result && result.data) {
      return yield put(deleteEmails.success(payload));
    }
    return yield put(deleteEmails.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(deleteEmails.failure(error));
    }
    return yield put(deleteEmails.failure(message));
  }
}

export default [
  takeLatest(Types.EMAILS_DELETE_REQUEST, deleteEmailsRequest),
];
