import { Types } from './endUser.actions';

export const INITIAL_STATE = {
  invoices: { error: null, loading: false },
  overviewSpend: { error: null, loading: false },
};

export function endUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.END_USER_FETCH_OVERVIEW_INVOICES_REQUEST:
      return {
        ...state,
        invoices: {
          loading: true,
          error: null,
        },
      };
    case Types.END_USER_FETCH_OVERVIEW_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case Types.END_USER_FETCH_OVERVIEW_INVOICES_CLEAR:
      return {
        ...state,
        invoices: {
          loading: false,
          error: null,
          data: [],
        },
      };
    case Types.END_USER_FETCH_OVERVIEW_INVOICES_FAILURE:
      return {
        ...state,
        invoices: {
          loading: false,
          error: action.payload,
        },
      };
    case Types.END_USER_FETCH_OVERVIEW_SPEND_REQUEST:
      return {
        ...state,
        overviewSpend: {
          loading: true,
          error: null,
        },
      };
    case Types.END_USER_FETCH_OVERVIEW_SPEND_SUCCESS: {
      return {
        ...state,
        overviewSpend: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case Types.END_USER_FETCH_OVERVIEW_SPEND_FAILURE:
      return {
        ...state,
        overviewSpend: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
