// Types
export const Types = {
  NETS_REQUEST: 'NETS_REQUEST',
  NETS_REQUEST_SUCCESS: 'NETS_REQUEST_SUCCESS',
  NETS_REQUEST_FAILURE: 'NETS_REQUEST_FAILURE',
};

// Actions
export const nets = {
  request: () => ({
    type: Types.NETS_REQUEST,
  }),
  success: payload => ({
    type: Types.NETS_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.NETS_REQUEST_FAILURE,
    payload,
  }),
};
