import { taxRatesReducer } from './taxRate.reducer';
import * as actions from './taxRate.actions';
import sagas from './taxRate.sagas';
import * as selector from './taxRate.selector';

export default {
  reducer: taxRatesReducer,
  actions,
  sagas,
  selector,
};
