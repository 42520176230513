import { adAccountReducer } from './adAccount.reducer';
import * as actions from './adAccount.actions';
import sagas from './adAccount.sagas';
import * as selector from './adAccount.selector';

export default {
  reducer: adAccountReducer,
  actions,
  sagas,
  selector,
};
