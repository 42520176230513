import { takeLatest, put, call } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Helpers
import { generateQueryString } from 'utils/helpers';

// Types
import { Types, fetchInvoices, fetchSpend } from './endUser.actions';

const BASE_ENDPOINT = '/endUser';

const getSpendData = async (advertiserId = '', fromDate = '', toDate = '') => {
  const url = generateQueryString(`${BASE_ENDPOINT}/spend`,
    {
      advertiserId: advertiserId.toString(),
      fromDate,
      toDate,
    });
  return api.get(url);
};

const findAllItems = async (advertiserId = '', adAccountId = '', fromDate = '', toDate = '') => {
  const url = generateQueryString(`${BASE_ENDPOINT}/invoices`,
    {
      advertiserId: advertiserId.toString(),
      adAccountId: adAccountId.toString(),
      fromDate,
      toDate,
    });
  return api.get(url);
};

function* getOverviewRecentInvoices({ payload }) {
  let result;
  try {
    result = yield call(findAllItems, payload.advertiserId, payload.adAccountId, payload.fromDate, payload.toDate);

    if (result && result.data) {
      return yield put(fetchInvoices.success(result.data));
    }
    return yield put(fetchInvoices.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(fetchInvoices.failure(error));
    }
    return yield put(fetchInvoices.failure(message));
  }
}

function* getOverviewSpend({ payload }) {
  let result;
  try {
    result = yield call(getSpendData, payload.advertiserId, payload.fromDate, payload.toDate);

    if (result && result.data) {
      return yield put(fetchSpend.success(result.data));
    }
    return yield put(fetchSpend.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(fetchSpend.failure(error));
    }
    return yield put(fetchSpend.failure(message));
  }
}

export default [
  takeLatest(Types.END_USER_FETCH_OVERVIEW_INVOICES_REQUEST, getOverviewRecentInvoices),
  takeLatest(Types.END_USER_FETCH_OVERVIEW_SPEND_REQUEST, getOverviewSpend),
];
