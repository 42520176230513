import { netsReducer } from './net.reducer';
import * as actions from './net.actions';
import sagas from './net.sagas';
import * as selector from './net.selector';

export default {
  reducer: netsReducer,
  actions,
  sagas,
  selector,
};
