export const Types = {
  PAYMENT_METHOD_FETCH_ALL_REQUEST: 'PAYMENT_METHOD_FETCH_ALL_REQUEST',
  PAYMENT_METHOD_FETCH_ALL_SUCCESS: 'PAYMENT_METHOD_FETCH_ALL_SUCCESS',
  PAYMENT_METHOD_FETCH_ALL_FAILURE: 'PAYMENT_METHOD_FETCH_ALL_FAILURE',
  PAYMENT_METHOD_CREATE_PAYMENT_METHOD_REQUEST: 'PAYMENT_METHOD_CREATE_PAYMENT_METHOD_REQUEST',
  PAYMENT_METHOD_CREATE_PAYMENT_METHOD_SUCCESS: 'PAYMENT_METHOD_CREATE_PAYMENT_METHOD_SUCCESS',
  PAYMENT_METHOD_CREATE_PAYMENT_METHOD_FAILURE: 'PAYMENT_METHOD_CREATE_PAYMENT_METHOD_FAILURE',
  PAYMENT_METHOD_CREATE_PAYMENT_METHOD_RESET: 'PAYMENT_METHOD_CREATE_PAYMENT_METHOD_RESET',
  PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_REQUEST: 'PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_REQUEST',
  PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS: 'PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS',
  PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_FAILURE: 'PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_FAILURE',
  PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_RESET: 'PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_RESET',
};

// Actions
export const fetchAllPaymentMethod = {
  request: (advertiserId, adAccountId) => ({
    type: Types.PAYMENT_METHOD_FETCH_ALL_REQUEST,
    payload: {
      advertiserId, adAccountId,
    },
  }),
  success: payload => ({
    type: Types.PAYMENT_METHOD_FETCH_ALL_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.PAYMENT_METHOD_FETCH_ALL_FAILURE,
    payload,
  }),
};

export const makeDefaultPaymentMethod = {
  request: payload => ({
    type: Types.PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_RESET,
  }),
};

export const createPaymentMethod = {
  request: payload => ({
    type: Types.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_RESET,
  }),
};
