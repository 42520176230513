import { call, takeLatest, put } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import { Types, advertisers } from './advertisers.actions';

const BASE_ENDPOINT = '/jampp';

const fetchList = async countryPath => api.get(
  `${BASE_ENDPOINT}/advertisers${countryPath ? `?country=${countryPath}` : ''}`,
);

function* AdvertisersRequest({ countryPath }) {
  try {
    const result = yield call(fetchList, countryPath);
    if (result && result.data) {
      return yield put(advertisers.success(result.data));
    }
    return yield put(advertisers.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(advertisers.failure(error));
    }
    return yield put(advertisers.failure(message));
  }
}

export default [
  takeLatest(Types.ADVERTISER_REQUEST, AdvertisersRequest),
];
