import { advertiserReducer } from './advertisers.reducer';
import * as actions from './advertisers.actions';
import sagas from './advertisers.sagas';
import * as selector from './advertisers.selector';

export default {
  reducer: advertiserReducer,
  actions,
  sagas,
  selector,
};
