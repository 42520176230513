import { currenciesReducer } from './currencies.reducer';
import * as actions from './currencies.actions';
import sagas from './currencies.sagas';
import * as selector from './currencies.selector';

export default {
  reducer: currenciesReducer,
  actions,
  sagas,
  selector,
};
