import { combineReducers } from 'redux';

// Reducers
import auth from './auth';
import user from './user';

import advertisers from './advertisers';
import countries from './countries';
import states from './states';
import adAccount from './adAccount';
import billingCenters from './billingCenters';
import adAccountTypes from './adAccountTypes';
import currencies from './currencies';
import paymentSettings from './paymentSettings';
import paymentSettingsTypes from './paymentSettingsTypes';
import paymentMethod from './paymentMethod';
import net from './net';
import taxIdType from './taxIdType';
import taxRateNames from './taxRateNames';
import taxRates from './taxRate';
import jamppBillingOffices from './jamppBillingOffice';
import brandingThemes from './brandingThemes';
import emails from './emails';
import notifications from './notifications';
import campaigns from './campaigns';
import adjustments from './adjustments';
import discountReason from './discountReason';
import invoice from './invoice';
import creditNotes from './creditNotes';
import endUser from './endUser';

const appReducers = combineReducers({
  auth: auth.reducer,
  user: user.reducer,
  advertisers: advertisers.reducer,
  countries: countries.reducer,
  states: states.reducer,
  adAccount: adAccount.reducer,
  billingCenters: billingCenters.reducer,
  adAccountTypes: adAccountTypes.reducer,
  currencies: currencies.reducer,
  paymentSettings: paymentSettings.reducer,
  paymentSettingsTypes: paymentSettingsTypes.reducer,
  paymentMethod: paymentMethod.reducer,
  nets: net.reducer,
  taxIdTypes: taxIdType.reducer,
  taxRateNames: taxRateNames.reducer,
  taxRates: taxRates.reducer,
  jamppBillingOffices: jamppBillingOffices.reducer,
  brandingThemes: brandingThemes.reducer,
  emails: emails.reducer,
  notifications: notifications.reducer,
  campaigns: campaigns.reducer,
  adjustments: adjustments.reducer,
  discountReason: discountReason.reducer,
  invoice: invoice.reducer,
  creditNotes: creditNotes.reducer,
  endUser: endUser.reducer,
});

export const rootReducer = (state, action) => {
  // Reset state of the store
  if (action.type === 'RESET_APP') {
    return appReducers({}, action);
  }

  return appReducers(state, action);
};
