export const Types = {
  STATES_FETCH_ALL_STATES_REQUEST: 'STATES_FETCH_ALL_STATES_REQUEST',
  STATES_FETCH_ALL_STATES_SUCCESS: 'STATES_FSTATES_FETCH_ALL_STATES_SUCCESSETCH_ALL_SUCCESS',
  STATES_FETCH_ALL_STATES_FAILURE: 'STATES_FETCH_ALL_STATES_FAILURE',
};

// Actions
export const fetchAllStates = {
  request: payload => ({
    type: Types.STATES_FETCH_ALL_STATES_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.STATES_FETCH_ALL_STATES_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.STATES_FETCH_ALL_STATES_FAILURE,
    payload,
  }),
};
