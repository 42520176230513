// Types
export const Types = {
  TAXIDTYPE_REQUEST: 'TAXIDTYPE_REQUEST',
  TAXIDTYPE_REQUEST_SUCCESS: 'TAXIDTYPE_REQUEST_SUCCESS',
  TAXIDTYPE_REQUEST_FAILURE: 'TAXIDTYPE_REQUEST_FAILURE',
};

// Actions
export const taxIdTypes = {
  request: () => ({
    type: Types.TAXIDTYPE_REQUEST,
  }),
  success: payload => ({
    type: Types.TAXIDTYPE_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.TAXIDTYPE_REQUEST_FAILURE,
    payload,
  }),
};
