import { Types } from './states.actions';

export const INITIAL_STATE = {
  loading: true,
  error: null,
};

export function statesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.STATES_FETCH_ALL_STATES_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.STATES_FETCH_ALL_STATES_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case Types.STATES_FETCH_ALL_STATES_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}
