import { call, takeLatest, put } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import {
  Types, adjustments, createAdjustments, editAdjustement, deleteAdjustment,
} from './adjustments.actions';

const BASE_ENDPOINT = '/discount';

const fetchList = async () => api.get(`${BASE_ENDPOINT}`);
const fetchFilterList = async queryString => {
  const result = await api.get(`${BASE_ENDPOINT}?${queryString}`);
  return result;
};
const create = async newAdjustmentData => api.post(`${BASE_ENDPOINT}`, newAdjustmentData);
const edit = async editAdjustmentData => {
  const { id, ...data } = editAdjustmentData;
  const result = await api.patch(`${BASE_ENDPOINT}/${id}`, data);
  return result;
};
const remove = async adjustmentId => api.delete(`${BASE_ENDPOINT}/${adjustmentId}`);

function* adjustmentsRequest({ payload }) {
  const {
    selectedAdvertisers, selectedAdAccounts, fromDate, toDate, country,
  } = payload;

  const query = [];
  if (selectedAdvertisers && selectedAdvertisers.length) query.push(`advertiserId=${selectedAdvertisers.toString()}`);
  if (selectedAdAccounts && selectedAdAccounts.length) query.push(`adAccountId=${selectedAdAccounts.toString()}`);
  if (fromDate && toDate) query.push(`from=${fromDate}&to=${toDate}`);
  if (country) query.push(`country=${country}`);
  const queryString = query.join('&');

  try {
    let result;
    if (queryString.length) {
      result = yield call(fetchFilterList, queryString);
    } else {
      result = yield call(fetchList);
    }
    if (result && result.data) {
      return yield put(adjustments.success(result.data));
    }
    return yield put(adjustments.failure('Unknown Error'));
  } catch (err) {
    if (err.response) {
      const { response: { data } } = err;
      if (data) {
        const error = typeof data === 'string' ? data : data.code;
        return yield put(adjustments.failure(error));
      }
    }
    return yield put(adjustments.failure(err.message));
  }
}

function* createAdjustment({ payload }) {
  let result;
  try {
    result = yield call(create, payload);
    if (result && result.data) {
      return yield put(createAdjustments.success(result.data));
    }
    return yield put(createAdjustments.failure('Unknown Error'));
  } catch (err) {
    if (err.response) {
      const { response: { data } } = err;
      if (data) {
        const error = typeof data === 'string' ? data : createAdjustments[data.code] || data.code;
        return yield put(createAdjustments.failure(error));
      }
    }
    return yield put(createAdjustments.failure(err.message));
  }
}

function* editAdjustmentRequest({ payload }) {
  try {
    const result = yield call(edit, payload);
    if (result && result.data) {
      return yield put(editAdjustement.success(result.data));
    }
    return yield put(editAdjustement.failure('Unknown Error'));
  } catch (err) {
    if (err.response) {
      const { response: { data } } = err;
      if (data) {
        const error = data.code;
        return yield put(editAdjustement.failure(error));
      }
    }
    return yield put(editAdjustement.failure(err.message));
  }
}

function* deleteAdjustmentRequest({ payload }) {
  let response;
  try {
    response = yield call(remove, payload.id);
    if (response.status === 200) {
      return yield put(deleteAdjustment.success(payload));
    }
    return yield put(deleteAdjustment.failure('Unknown Error'));
  } catch (error) {
    return yield put(deleteAdjustment.failure({ error, name: payload.accountName }));
  }
}

export default [
  takeLatest(Types.ADJUSTMENTS_REQUEST, adjustmentsRequest),
  takeLatest(Types.CREATE_ADJUSTMENTS_REQUEST, createAdjustment),
  takeLatest(Types.EDIT_ADJUSTMENT_REQUEST, editAdjustmentRequest),
  takeLatest(Types.DELETE_ADJUSTMENT_REQUEST, deleteAdjustmentRequest),
];
