export const Types = {
  PAYMENT_SETTINGS_TYPES_FETCH_ALL_REQUEST: 'PAYMENT_SETTINGS_TYPES_FETCH_ALL_REQUEST',
  PAYMENT_SETTINGS_TYPES_FETCH_ALL_SUCCESS: 'PAYMENT_SETTINGS_TYPES_FETCH_ALL_SUCCESS',
  PAYMENT_SETTINGS_TYPES_FETCH_ALL_FAILURE: 'PAYMENT_SETTINGS_TYPES_FETCH_ALL_FAILURE',
};

// Actions
export const fetchAllPaymentSettingsTypes = {
  request: () => ({
    type: Types.PAYMENT_SETTINGS_TYPES_FETCH_ALL_REQUEST,
  }),
  success: payload => ({
    type: Types.PAYMENT_SETTINGS_TYPES_FETCH_ALL_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.PAYMENT_SETTINGS_TYPES_FETCH_ALL_FAILURE,
    payload,
  }),
};
