// Types
export const Types = {
  CAMPAIGNS_REQUEST: 'CAMPAIGNS_REQUEST',
  CAMPAIGNS_REQUEST_SUCCESS: 'CAMPAIGNS_REQUEST_SUCCESS',
  CAMPAIGNS_REQUEST_FAILURE: 'CAMPAIGNS_REQUEST_FAILURE',
  CAMPAIGNS_TOGGLE_TEST_REQUEST: 'CAMPAIGNS_TOGGLE_TEST_REQUEST',
  CAMPAIGNS_TOGGLE_TEST_SUCCESS: 'CAMPAIGNS_TOGGLE_TEST_SUCCESS',
  CAMPAIGNS_TOGGLE_TEST_FAILURE: 'CAMPAIGNS_TOGGLE_TEST_FAILURE',
  CAMPAIGNS_FROM_JAMPP_REQUEST: 'CAMPAIGNS_FROM_JAMPP_REQUEST',
  CAMPAIGNS_FROM_JAMPP_SUCCESS: 'CAMPAIGNS_FROM_JAMPP_SUCCESS',
  CAMPAIGNS_FROM_JAMPP_FAILURE: 'CAMPAIGNS_FROM_JAMPP_FAILURE',
  CAMPAIGNS_CURRENT_SPEND_REQUEST: 'CAMPAIGNS_CURRENT_SPEND_REQUEST',
  CAMPAIGNS_CURRENT_SPEND_SUCCESS: 'CAMPAIGNS_CURRENT_SPEND_SUCCESS',
  CAMPAIGNS_CURRENT_SPEND_FAILURE: 'CAMPAIGNS_CURRENT_SPEND_FAILURE',
  CAMPAIGNS_CSV_DATA: 'CAMPAIGNS_CSV_DATA',
};

// Actions
export const campaigns = {
  request: payload => ({
    type: Types.CAMPAIGNS_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CAMPAIGNS_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CAMPAIGNS_REQUEST_FAILURE,
    payload,
  }),
};

export const toggleTest = {
  request: payload => ({
    type: Types.CAMPAIGNS_TOGGLE_TEST_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CAMPAIGNS_TOGGLE_TEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CAMPAIGNS_TOGGLE_TEST_FAILURE,
    payload,
  }),
};

export const jamppCampaigns = {
  request: payload => ({
    type: Types.CAMPAIGNS_FROM_JAMPP_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CAMPAIGNS_FROM_JAMPP_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CAMPAIGNS_FROM_JAMPP_FAILURE,
    payload,
  }),
};

export const campaignsCurrentSpend = {
  request: payload => ({
    type: Types.CAMPAIGNS_CURRENT_SPEND_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CAMPAIGNS_CURRENT_SPEND_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CAMPAIGNS_CURRENT_SPEND_FAILURE,
    payload,
  }),
};

export const CSVData = {
  create: payload => ({
    type: Types.CAMPAIGNS_CSV_DATA,
    payload,
  }),
};
