import { emailsReducer } from './emails.reducer';
import * as actions from './emails.actions';
import sagas from './emails.sagas';
import * as selector from './emails.selector';

export default {
  reducer: emailsReducer,
  actions,
  sagas,
  selector,
};
