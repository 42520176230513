export const Types = {
  AD_ACCOUNT_FETCH_ALL_REQUEST: 'AD_ACCOUNT_FETCH_ALL_REQUEST',
  AD_ACCOUNT_FETCH_ALL_SUCCESS: 'AD_ACCOUNT_FETCH_ALL_SUCCESS',
  AD_ACCOUNT_FETCH_ALL_FAILURE: 'AD_ACCOUNT_FETCH_ALL_FAILURE',
  AD_ACCOUNT_FETCH_ONE_REQUEST: 'AD_ACCOUNT_FETCH_ONE_REQUEST',
  AD_ACCOUNT_FETCH_ONE_SUCCESS: 'AD_ACCOUNT_FETCH_ONE_SUCCESS',
  AD_ACCOUNT_FETCH_ONE_FAILURE: 'AD_ACCOUNT_FETCH_ONE_FAILURE',
  AD_ACCOUNT_SELECT_CURRENT_ENDUSER: 'AD_ACCOUNT_SELECT_CURRENT_ENDUSER',
  AD_ACCOUNT_CREATE_AD_ACCOUNT_REQUEST: 'AD_ACCOUNT_CREATE_AD_ACCOUNT_REQUEST',
  AD_ACCOUNT_CREATE_AD_ACCOUNT_SUCCESS: 'AD_ACCOUNT_CREATE_AD_ACCOUNT_SUCCESS',
  AD_ACCOUNT_CREATE_AD_ACCOUNT_FAILURE: 'AD_ACCOUNT_CREATE_AD_ACCOUNT_FAILURE',
  AD_ACCOUNT_CREATE_AD_ACCOUNT_RESET: 'AD_ACCOUNT_CREATE_AD_ACCOUNT_RESET',
  AD_ACCOUNT_EDIT_AD_ACCOUNT_REQUEST: 'AD_ACCOUNT_EDIT_AD_ACCOUNT_REQUEST',
  AD_ACCOUNT_EDIT_AD_ACCOUNT_SUCCESS: 'AD_ACCOUNT_EDIT_AD_ACCOUNT_SUCCESS',
  AD_ACCOUNT_EDIT_AD_ACCOUNT_FAILURE: 'AD_ACCOUNT_EDIT_AD_ACCOUNT_FAILURE',
  AD_ACCOUNT_EDIT_AD_ACCOUNT_RESET: 'AD_ACCOUNT_EDIT_AD_ACCOUNT_RESET',

  AD_ACCOUNT_DELETE_AD_ACCOUNT_REQUEST: 'AD_ACCOUNT_DELETE_AD_ACCOUNT_REQUEST',
  AD_ACCOUNT_DELETE_AD_ACCOUNT_SUCCESS: 'AD_ACCOUNT_DELETE_AD_ACCOUNT_SUCCESS',
  AD_ACCOUNT_DELETE_AD_ACCOUNT_FAILURE: 'AD_ACCOUNT_DELETE_AD_ACCOUNT_FAILURE',
  AD_ACCOUNT_DELETE_AD_ACCOUNT_RESET: 'AD_ACCOUNT_DELETE_AD_ACCOUNT_RESET',

  AD_ACCOUNT_TOGGLE_STATUS_REQUEST: 'AD_ACCOUNT_TOGGLE_STATUS_REQUEST',
  AD_ACCOUNT_TOGGLE_STATUS_SUCCESS: 'AD_ACCOUNT_TOGGLE_STATUS_SUCCESS',
  AD_ACCOUNT_TOGGLE_STATUS_FAILURE: 'AD_ACCOUNT_TOGGLE_STATUS_FAILURE',

  AD_ACCOUNT_NOTIFICATION_RESET: 'AD_ACCOUNT_NOTIFICATION_RESET',
};

// Actions
export const fetchAllAdAccounts = {
  request: (payload, temp = false, country = '') => ({
    type: Types.AD_ACCOUNT_FETCH_ALL_REQUEST,
    payload,
    temp,
    country,
  }),
  success: (payload, temp = false) => ({
    type: Types.AD_ACCOUNT_FETCH_ALL_SUCCESS,
    payload,
    temp,
  }),
  failure: payload => ({
    type: Types.AD_ACCOUNT_FETCH_ALL_FAILURE,
    payload,
  }),
};

export const fetchSingleAdAccount = {
  request: payload => ({
    type: Types.AD_ACCOUNT_FETCH_ONE_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.AD_ACCOUNT_FETCH_ONE_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.AD_ACCOUNT_FETCH_ONE_FAILURE,
    payload,
  }),
};

export const createAdAccount = {
  request: payload => ({
    type: Types.AD_ACCOUNT_CREATE_AD_ACCOUNT_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.AD_ACCOUNT_CREATE_AD_ACCOUNT_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.AD_ACCOUNT_CREATE_AD_ACCOUNT_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.AD_ACCOUNT_CREATE_AD_ACCOUNT_RESET,
  }),
};

export const editAdAccount = {
  request: payload => ({
    type: Types.AD_ACCOUNT_EDIT_AD_ACCOUNT_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.AD_ACCOUNT_EDIT_AD_ACCOUNT_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.AD_ACCOUNT_EDIT_AD_ACCOUNT_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.AD_ACCOUNT_EDIT_AD_ACCOUNT_RESET,
  }),
};

export const deleteAdAccount = {
  request: payload => ({
    type: Types.AD_ACCOUNT_DELETE_AD_ACCOUNT_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.AD_ACCOUNT_DELETE_AD_ACCOUNT_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.AD_ACCOUNT_DELETE_AD_ACCOUNT_FAILURE,
    payload,
  }),
};

export const toggleStatusAdAccount = {
  request: payload => ({
    type: Types.AD_ACCOUNT_TOGGLE_STATUS_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.AD_ACCOUNT_TOGGLE_STATUS_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.AD_ACCOUNT_TOGGLE_STATUS_FAILURE,
    payload,
  }),
};

export const endUserAdAccount = {
  select: payload => ({
    type: Types.AD_ACCOUNT_SELECT_CURRENT_ENDUSER,
    payload,
  }),
};
