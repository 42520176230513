import { userReducer } from './user.reducer';
import * as actions from './user.actions';
import sagas from './user.sagas';
import * as selector from './user.selector';
import * as enums from './user.enum';

export default {
  reducer: userReducer,
  actions,
  sagas,
  selector,
  enums,
};
