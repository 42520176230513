import FileDownload from 'js-file-download';
import { takeLatest, put, call } from 'redux-saga/effects';

// Services
import { api } from 'services/api';

// Helpers
import { generateQueryString } from 'utils/helpers';

// Types
import {
  Types, fetchCreditNotes, generateRegularCsv, generateXeroCsv, generateColppyCsv, createCreditNote,
  editCreditNote, deleteCreditNote,
} from './creditNotes.actions';

// Errors
import CreditNoteEror from './creditNotes.error';

const BASE_ENDPOINT = '/creditNote';

const findAllRequest = async ({ payload }) => {
  const url = generateQueryString(BASE_ENDPOINT, payload);
  return api.get(url);
};

const create = async newCreditNoteData => api.post(`${BASE_ENDPOINT}`, newCreditNoteData);
const edit = async (id, items) => api.patch(`${BASE_ENDPOINT}/${id}`, { items });
const deleteCN = async id => api.delete(`${BASE_ENDPOINT}/${id}`);

const generateAndFetchRegularCsvs = async ({ payload }) => {
  const url = generateQueryString(`${BASE_ENDPOINT}/download/csv`, payload);
  const csv = await api.get(url);
  return csv;
};

const generateAndFetchXeroCsvs = async ({ payload }) => {
  const url = generateQueryString(`${BASE_ENDPOINT}/download/xero`, payload);
  const csv = await api.get(url);
  return csv;
};

const generateAndFetchColppyCsvs = async ({ payload }) => {
  const url = generateQueryString(`${BASE_ENDPOINT}/download/colppy`, payload);
  const csv = await api.get(url);
  return csv;
};

function* fetchFilteredCreditNotes(payload) {
  let result;

  try {
    result = yield call(findAllRequest, payload);

    if (result && result.data) {
      return yield put(fetchCreditNotes.success(result.data));
    }
    return yield put(fetchCreditNotes.failure('Unknown error fetching credit notes data'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      let { message } = error;
      if (data) {
        message = typeof data === 'string' ? data : data.code;
      }
      return yield put(fetchCreditNotes.failure(message));
    }
    return yield put(fetchCreditNotes.failure(error));
  }
}

function* createNewCreditNote({ payload }) {
  let result;
  try {
    result = yield call(create, payload);

    if (result && result.data) {
      return yield put(createCreditNote.success(result.data));
    }
    return yield put(createCreditNote.failure('Unknown Error'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      let { message } = error;
      if (data) {
        message = typeof data === 'string' ? data : CreditNoteEror[data.code] || data.code;
      }
      return yield put(createCreditNote.failure(message));
    }
    return yield put(createCreditNote.failure(error));
  }
}

function* editCreditNoteAction({ payload }) {
  let result;
  try {
    const { id, items } = payload;
    result = yield call(edit, id, items);

    if (result && result.data) {
      return yield put(editCreditNote.success(result.data));
    }
    return yield put(editCreditNote.failure('Unknown Error'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      let { message } = error;
      if (data) {
        message = typeof data === 'string' ? data : CreditNoteEror[data.code] || data.code;
      }
      return yield put(editCreditNote.failure(message));
    }
    return yield put(editCreditNote.failure(error));
  }
}

function* deleteCreditNoteAction({ payload: id }) {
  let result;
  try {
    result = yield call(deleteCN, id);

    if (result && result.data) {
      return yield put(deleteCreditNote.success(id));
    }
    return yield put(deleteCreditNote.failure('Unknown Error'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      let { message } = error;
      if (data) {
        message = typeof data === 'string' ? data : CreditNoteEror[data.code] || data.code;
      }
      return yield put(deleteCreditNote.failure(message));
    }
    return yield put(deleteCreditNote.failure(error));
  }
}

function* getRegularCsvs(payload) {
  let result;

  try {
    result = yield call(generateAndFetchRegularCsvs, payload);

    if (result && result.data) {
      const fileName = result.headers['content-disposition'].split('filename=')[1].trim().replace(/"/g, '');
      FileDownload(result.data, fileName);
      return yield put(generateRegularCsv.success());
    }
    return yield put(generateRegularCsv.failure('Unknown error generating credit note CSV'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      let { message } = error;
      if (data) {
        message = typeof data === 'string' ? data : data.code;
      }
      return yield put(generateRegularCsv.failure(message));
    }
    return yield put(generateRegularCsv.failure(error));
  }
}

function* getXeroCsvs(payload) {
  let result;

  try {
    result = yield call(generateAndFetchXeroCsvs, payload);

    if (result && result.data) {
      const fileName = result.headers['content-disposition'].split('filename=')[1].trim().replace(/"/g, '');
      FileDownload(result.data, fileName);
      return yield put(generateXeroCsv.success());
    }
    return yield put(generateXeroCsv.failure('Unknown error generating Xero credit note CSV'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      let { message } = error;
      if (data) {
        message = typeof data === 'string' ? data : data.code;
      }
      return yield put(generateXeroCsv.failure(message));
    }
    return yield put(generateXeroCsv.failure(error));
  }
}

function* getColppyCsvs(payload) {
  let result;

  try {
    result = yield call(generateAndFetchColppyCsvs, payload);

    if (result && result.data) {
      const fileName = result.headers['content-disposition'].split('filename=')[1].trim().replace(/"/g, '');
      FileDownload(result.data, fileName);
      return yield put(generateColppyCsv.success());
    }
    return yield put(generateColppyCsv.failure('Unknown error generating Colppy invoice CSV'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      let { message } = error;
      if (data) {
        message = typeof data === 'string' ? data : data.code;
      }
      return yield put(generateColppyCsv.failure(message));
    }
    return yield put(generateColppyCsv.failure(error));
  }
}

export default [
  takeLatest(Types.CREDIT_NOTES_FETCH_ALL_REQUEST, fetchFilteredCreditNotes),
  takeLatest(Types.CREDIT_NOTES_CREATION_REQUEST, createNewCreditNote),
  takeLatest(Types.CREDIT_NOTES_EDIT_REQUEST, editCreditNoteAction),
  takeLatest(Types.CREDIT_NOTES_DELETE_REQUEST, deleteCreditNoteAction),
  takeLatest(Types.CREDIT_NOTES_GENERATE_REGULAR_CSV_REQUEST, getRegularCsvs),
  takeLatest(Types.CREDIT_NOTES_GENERATE_XERO_CSV_REQUEST, getXeroCsvs),
  takeLatest(Types.CREDIT_NOTES_GENERATE_COLPPY_CSV_REQUEST, getColppyCsvs),
];
