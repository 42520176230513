import { Types as AdjustmentsTypes } from 'store/adjustments/adjustments.actions';

import { Types } from './campaigns.actions';

export const INITIAL_STATE = {
  campaigns: [],
  actions: {
    loading: false,
    error: null,
    success: null,
  },
  jampp: {
    loading: false,
    list: [],
    error: null,
  },
  csvData: {},
  loading: true,
  loadingSpend: false,
};

export function campaignsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CAMPAIGNS_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.CAMPAIGNS_REQUEST_SUCCESS:
      return {
        ...state, loading: false, error: null, campaigns: [...action.payload],
      };
    case Types.CAMPAIGNS_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case Types.CAMPAIGNS_TOGGLE_TEST_REQUEST:
      return { ...state, actions: { ...INITIAL_STATE.actions, loading: true } };
    case Types.CAMPAIGNS_TOGGLE_TEST_SUCCESS:
      return {
        ...state,
        actions: { ...state.actions, loading: false, success: action.payload },
        campaigns: state.campaigns.map(c => (c.CampaignId === parseInt(action.payload.JamppId, 10)
          ? { ...c, isTest: action.payload.isTest }
          : c
        )),
      };
    case Types.CAMPAIGNS_TOGGLE_TEST_FAILURE:
      return { ...state, actions: { ...state.actions, loading: false, error: action.payload } };
    case Types.CAMPAIGNS_FROM_JAMPP_REQUEST:
      return { ...state, jampp: { ...INITIAL_STATE.jampp, loading: true } };
    case Types.CAMPAIGNS_FROM_JAMPP_SUCCESS:
      return { ...state, jampp: { loading: false, error: null, list: action.payload } };
    case Types.CAMPAIGNS_FROM_JAMPP_FAILURE:
      return { ...state, jampp: { loading: false, error: action.payload, list: [] } };
    case Types.CAMPAIGNS_CURRENT_SPEND_REQUEST:
      return { ...state, loadingSpend: true };
    case Types.CAMPAIGNS_CURRENT_SPEND_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map(campaign => {
          if (campaign.id === -1) {
            const spendInfo = action.payload.find(a => a.campaign.id === campaign.CampaignId);
            if (!spendInfo || spendInfo.metrics.spend === 0) return campaign;
            return {
              ...campaign,
              units: spendInfo.metrics.units || 0,
              spend: spendInfo.metrics.spend || 0,
              pricePerUnit: spendInfo.metrics.pricePerUnit || 0,
              foreignSpend: spendInfo.metrics.foreignSpend || 0,
              foreignPricePerUnit: spendInfo.metrics.foreignPricePerUnit || 0,
            };
          }
          return campaign;
        }),
        loadingSpend: false,
      };
    case Types.CAMPAIGNS_CURRENT_SPEND_FAILURE:
      return { ...state, loadingSpend: false };
    case Types.CAMPAIGNS_CSV_DATA:
      return { ...state, csvData: { ...action.payload } };

    case AdjustmentsTypes.CREATE_ADJUSTMENTS_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map(campaign => {
          if (campaign.id === -1 && campaign.CampaignId === action.payload.CampaignId) {
            return {
              ...campaign,
              Discounts: [...campaign.Discounts, action.payload],
            };
          }
          return campaign;
        }),
      };
    case AdjustmentsTypes.DELETE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map(campaign => {
          if (campaign.id === -1 && campaign.CampaignId === action.payload.CampaignId) {
            return {
              ...campaign,
              Discounts: campaign.Discounts.filter(d => d.id !== action.payload.id),
            };
          }
          return campaign;
        }),
      };
    case AdjustmentsTypes.EDIT_ADJUSTMENT_SUCCESS: {
      const DiscountId = parseInt(action.payload.id, 10);
      const campaign = state.campaigns.find(
        c => c.id === -1 && c.CampaignId === parseInt(action.payload.CampaignId, 10),
      );
      if (!campaign) return state;
      let otherCampaign = null;
      if (!campaign.Discounts.some(d => d.id === DiscountId)) {
        otherCampaign = state.campaigns.find(c => c.Discounts.some(d => d.id === DiscountId));
      }
      return {
        ...state,
        campaigns: state.campaigns.map(c => {
          if (c.id === -1) {
            if (c.CampaignId === campaign.CampaignId) {
              return {
                ...c,
                Discounts: !otherCampaign
                  ? c.Discounts.map(d => (d.id === DiscountId ? action.payload : d))
                  : [...c.Discounts, action.payload],
              };
            }
            if (otherCampaign && c.CampaignId === otherCampaign.CampaignId) {
              return {
                ...c,
                Discounts: c.Discounts.filter(d => d.id !== DiscountId),
              };
            }
          }
          return c;
        }),
      };
    }
    default:
      return state;
  }
}
