import { brandingThemesReducer } from './brandingThemes.reducer';
import * as actions from './brandingThemes.actions';
import sagas from './brandingThemes.sagas';
import * as selector from './brandingThemes.selector';

export default {
  reducer: brandingThemesReducer,
  actions,
  sagas,
  selector,
};
