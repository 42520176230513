import { Types } from './adAccountTypes.actions';

export const INITIAL_STATE = {
  loading: true,
  error: null,
};

export function adAccountTypesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_REQUEST_SUCCES:
      return { ...state, data: action.payload, loading: false };
    case Types.AD_ACCOUNT_TYPES_FETCH_ALL_AD_ACCOUNT_TYPES_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}
