import FileDownload from 'js-file-download';
import { takeLatest, put, call } from 'redux-saga/effects';

// Services
import { api } from 'services/api';

// Helpers
import { generateQueryString } from 'utils/helpers';

// Types
import {
  Types, fetchInvoices, generateRegularCsv, generateXeroCsv, editInvoice, generateColppyCsv,
  generateConsolidatedCsv, generateBrazilTXT,
} from './invoice.actions';

import { InvoiceError } from './invoice.error';

const BASE_ENDPOINT = '/invoice';

const findAllRequest = async ({ payload }) => {
  const url = generateQueryString(BASE_ENDPOINT, payload);
  return api.get(url);
};

const editRequest = async (invoiceData, invoiceId) => {
  const url = `${BASE_ENDPOINT}/${invoiceId}`;
  return api.patch(url, invoiceData);
};

const generateAndFetchRegularCsvs = async ({ payload }) => {
  const url = generateQueryString(`${BASE_ENDPOINT}/download/csv`, payload);
  const csv = await api.get(url);
  return csv;
};

const generateAndFetchXeroCsvs = async ({ payload }) => {
  const url = generateQueryString(`${BASE_ENDPOINT}/download/xero`, payload);
  const csv = await api.get(url);
  return csv;
};

const generateAndColppyCsvs = async ({ payload }) => {
  const url = generateQueryString(`${BASE_ENDPOINT}/download/colppy`, payload);
  const csv = await api.get(url);
  return csv;
};

const generateAndConsolidatedCsvs = async ({ payload }) => {
  const url = generateQueryString(`${BASE_ENDPOINT}/download/consolidated`, payload);
  const csv = await api.get(url);
  return csv;
};

const generateAndBrazilTXT = async ({ invoiceId }) => {
  const txt = await api.get(`${BASE_ENDPOINT}/brazil-txt/${invoiceId}`);
  return txt;
};

function* fetchFilteredInvoices(payload) {
  let result;

  try {
    result = yield call(findAllRequest, payload);

    if (result && result.data) {
      return yield put(fetchInvoices.success(result.data));
    }
    return yield put(fetchInvoices.failure('Unknown error fetching invoice data'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      if (data) {
        const err = typeof data === 'string' ? data : data.code;
        return yield put(fetchInvoices.failure(err));
      }
    }
    return yield put(fetchInvoices.failure(error.message));
  }
}

function* updateInvoice({ payload }) {
  let result;
  try {
    result = yield call(editRequest, payload.invoiceData, payload.invoiceId);

    if (result && result.data) {
      return yield put(editInvoice.success(payload));
    }
    return yield put(editInvoice.failure('Unknown Error'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      if (data) {
        const err = typeof data === 'string' ? data : InvoiceError[data.code] || data.code;
        return yield put(editInvoice.failure(err));
      }
    }
    return yield put(editInvoice.failure(error.message));
  }
}

function* getRegularCsvs(payload) {
  let result;

  try {
    result = yield call(generateAndFetchRegularCsvs, payload);

    if (result && result.data) {
      const fileName = result.headers['content-disposition'].split('filename=')[1].trim().replace(/"/g, '');
      FileDownload(result.data, fileName);
      return yield put(generateRegularCsv.success());
    }
    return yield put(generateRegularCsv.failure('Unknown error generating invoice CSV'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      if (data) {
        const err = typeof data === 'string' ? data : data.code;
        return yield put(generateRegularCsv.failure(err));
      }
    }
    return yield put(generateRegularCsv.failure(error.message));
  }
}

function* getXeroCsvs(payload) {
  let result;

  try {
    result = yield call(generateAndFetchXeroCsvs, payload);

    if (result && result.data) {
      const fileName = result.headers['content-disposition'].split('filename=')[1].trim().replace(/"/g, '');
      FileDownload(result.data, fileName);
      return yield put(generateXeroCsv.success());
    }
    return yield put(generateXeroCsv.failure('Unknown error generating Xero invoice CSV'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      if (data) {
        const err = typeof data === 'string' ? data : data.code;
        return yield put(generateXeroCsv.failure(err));
      }
    }
    return yield put(generateXeroCsv.failure(error.message));
  }
}

function* getColppyCsvs(payload) {
  let result;

  try {
    result = yield call(generateAndColppyCsvs, payload);

    if (result && result.data) {
      const fileName = result.headers['content-disposition'].split('filename=')[1].trim().replace(/"/g, '');
      FileDownload(result.data, fileName);
      return yield put(generateColppyCsv.success());
    }
    return yield put(generateColppyCsv.failure('Unknown error generating Colppy invoice CSV'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      if (data) {
        const err = typeof data === 'string' ? data : data.code;
        return yield put(generateColppyCsv.failure(err));
      }
    }
    return yield put(generateColppyCsv.failure(error.message));
  }
}

function* getConsolidatedCsvs(payload) {
  let result;

  try {
    result = yield call(generateAndConsolidatedCsvs, payload);

    if (result && result.data) {
      const fileName = result.headers['content-disposition'].split('filename=')[1].trim().replace(/"/g, '');
      FileDownload(result.data, fileName);
      return yield put(generateConsolidatedCsv.success());
    }
    return yield put(generateConsolidatedCsv.failure('Unknown error generating Consolidated invoice CSV'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      if (data) {
        const err = typeof data === 'string' ? data : data.code;
        return yield put(generateConsolidatedCsv.failure(err));
      }
    }
    return yield put(generateConsolidatedCsv.failure(error.message));
  }
}

function* getBrazilTXT({ payload }) {
  try {
    const result = yield call(generateAndBrazilTXT, payload);

    if (result && result.data) {
      return yield put(generateBrazilTXT.success(result.data));
    }
    return yield put(generateBrazilTXT.failure('Unknown error generating Brazil TXT invoice'));
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      if (data) {
        const err = typeof data === 'string' ? data : data.code;
        return yield put(generateBrazilTXT.failure(err));
      }
    }
    return yield put(generateBrazilTXT.failure(error.message));
  }
}

export default [
  takeLatest(Types.INVOICES_FETCH_ALL_REQUEST, fetchFilteredInvoices),
  takeLatest(Types.INVOICES_EDIT_REQUEST, updateInvoice),
  takeLatest(Types.INVOICES_GENERATE_REGULAR_CSV_REQUEST, getRegularCsvs),
  takeLatest(Types.INVOICES_GENERATE_XERO_CSV_REQUEST, getXeroCsvs),
  takeLatest(Types.INVOICES_GENERATE_COLPPY_CSV_REQUEST, getColppyCsvs),
  takeLatest(Types.INVOICES_GENERATE_CONSOLIDATED_CSV_REQUEST, getConsolidatedCsvs),
  takeLatest(Types.INVOICES_GENERATE_BR_TXT_REQUEST, getBrazilTXT),
];
