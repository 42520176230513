// Types
export const Types = {
  CURRENT_USER_REQUEST: 'CURRENT_USER_REQUEST',
  CURRENT_USER_REQUEST_SUCCESS: 'CURRENT_USER_REQUEST_SUCCESS',
  CURRENT_USER_REQUEST_FAILURE: 'CURRENT_USER_REQUEST_FAILURE',
};

// Actions
export const currentUser = {
  request: () => ({
    type: Types.CURRENT_USER_REQUEST,
  }),
  success: payload => ({
    type: Types.CURRENT_USER_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CURRENT_USER_REQUEST_FAILURE,
    payload,
  }),
};
