import { adjustmentsReducer } from './adjustments.reducer';
import * as actions from './adjustments.actions';
import sagas from './adjustments.sagas';
import * as selector from './adjustments.selector';

export default {
  reducer: adjustmentsReducer,
  actions,
  sagas,
  selector,
};
