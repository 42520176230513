import { takeLatest, put, call } from 'redux-saga/effects';

// Services
import { api } from 'services/api';

// Types
import { Types, fetchAllPaymentSettings, createPaymentSettings } from './paymentSettings.actions';

// Errors
import PaymentMethodError from './paymentSetting.error';

const BASE_ENDPOINT = '/paymentSetting';

const findAllRequest = async advertiserId => api.get(`${BASE_ENDPOINT}?advertiser=${advertiserId}`);
const create = async newPaymentSettingData => api.post(`${BASE_ENDPOINT}`, newPaymentSettingData);

function* fetchPaymentSettings({ payload: advertiserId }) {
  let result;

  try {
    result = yield call(findAllRequest, advertiserId);

    if (result && result.data) {
      return yield put(fetchAllPaymentSettings.success(result.data));
    }
    return yield put(fetchAllPaymentSettings.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(fetchAllPaymentSettings.failure(error));
    }
    return yield put(fetchAllPaymentSettings.failure(message));
  }
}

function* createNewPaymentSetting({ payload }) {
  let result;

  try {
    result = yield call(create, payload);

    if (result && result.data) {
      return yield put(createPaymentSettings.success(result.data));
    }
    return yield put(createPaymentSettings.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : PaymentMethodError[data.code] || data.code;
      return yield put(createPaymentSettings.failure(error));
    }
    return yield put(createPaymentSettings.failure(message));
  }
}

export default [
  takeLatest(Types.PAYMENT_SETTING_FETCH_ALL_REQUEST, fetchPaymentSettings),
  takeLatest(Types.PAYMENT_SETTING_CREATE_PAYMENT_SETTING_REQUEST, createNewPaymentSetting),
];
