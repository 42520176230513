import { Types } from './invoice.actions';

export const INITIAL_STATE = {
  error: null,
  loading: false,
  edition: { error: null, loading: false },
  regularCsv: { error: null, loading: false },
  xeroCsv: { error: null, loading: false },
  colppyCsv: { error: null, loading: false },
  brazilTxt: { error: null, loading: false, data: null },
};

export function invoiceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.INVOICES_FETCH_ALL_REQUEST:
      return {
        ...state, loading: true, error: null, data: undefined,
      };
    case Types.INVOICES_FETCH_ALL_REQUEST_SUCCESS: {
      return {
        ...state, loading: false, error: null, data: action.payload,
      }; }
    case Types.INVOICES_FETCH_ALL_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case Types.INVOICES_EDIT_REQUEST:
      return {
        ...state, creation: { loading: true, error: null },
      };
    case Types.INVOICES_EDIT_SUCCESS: {
      if (action.payload.invoiceId && action.payload.invoiceData) {
        const { invoiceId, invoiceData } = action.payload;
        return {
          ...state,
          creation: { data: 'OK', loading: false, error: null },
          data: (state.data || []).map(i => (i.id !== invoiceId ? i : { ...i, ...invoiceData })),
        };
      }
      return {
        ...state, creation: { data: action.payload, loading: false, error: null },
      };
    }
    case Types.INVOICES_EDIT_FAILURE:
      return { ...state, creation: { loading: false, error: action.payload } };
    case Types.INVOICES_GENERATE_REGULAR_CSV_REQUEST:
      return {
        ...state, regularCsv: { loading: true, error: null },
      };
    case Types.INVOICES_GENERATE_REGULAR_CSV_SUCCESS: {
      return {
        ...state, regularCsv: { data: action.payload, loading: false, error: null },
      }; }
    case Types.INVOICES_GENERATE_REGULAR_CSV_FAILURE:
      return { ...state, regularCsv: { loading: false, error: action.payload } };
    case Types.INVOICES_GENERATE_XERO_CSV_REQUEST:
      return {
        ...state, xeroCsv: { loading: true, error: null },
      };
    case Types.INVOICES_GENERATE_XERO_CSV_SUCCESS: {
      return {
        ...state, xeroCsv: { data: action.payload, loading: false, error: null },
      }; }
    case Types.INVOICES_GENERATE_XERO_CSV_FAILURE:
      return { ...state, xeroCsv: { loading: false, error: action.payload } };
    case Types.INVOICES_GENERATE_COLPPY_CSV_REQUEST:
      return {
        ...state, xeroCsv: { loading: true, error: null },
      };
    case Types.INVOICES_GENERATE_COLPPY_CSV_SUCCESS: {
      return {
        ...state, xeroCsv: { data: action.payload, loading: false, error: null },
      }; }
    case Types.INVOICES_GENERATE_COLPPY_CSV_FAILURE:
      return { ...state, xeroCsv: { loading: false, error: action.payload } };
    case Types.INVOICES_GENERATE_BR_TXT_REQUEST:
      return {
        ...state,
        brazilTxt: { error: null, loading: true, data: null },
      };
    case Types.INVOICES_GENERATE_BR_TXT_SUCCESS:
      return {
        ...state,
        brazilTxt: { error: null, loading: false, data: action.payload },
      };
    case Types.INVOICES_GENERATE_BR_TXT_FAILURE:
      return {
        ...state,
        brazilTxt: { error: action.payload, loading: false, data: null },
      };
    default:
      return state;
  }
}
