import { Types } from './jamppBillingOffice.actions';

export const INITIAL_STATE = {
  jamppBillingOffices: [],
  loading: false,
};

export function jamppBillingOfficesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.JAMPPBILLINGOFFICE_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.JAMPPBILLINGOFFICE_REQUEST_SUCCESS: {
      return {
        ...state, loading: false, error: null, jamppBillingOffices: [...action.payload],
      }; }
    case Types.JAMPPBILLINGOFFICE_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
