import { Types } from './creditNotes.actions';

export const INITIAL_STATE = {
  error: null,
  loading: false,
  creation: { error: null, loading: false },
  deletion: { error: null, loading: false },
  regularCsv: { error: null, loading: false },
  xeroCsv: { error: null, loading: false },
  colppyCsv: { error: null, loading: false },
};

export function creditNotesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CREDIT_NOTES_FETCH_ALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Types.CREDIT_NOTES_FETCH_ALL_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }; }
    case Types.CREDIT_NOTES_FETCH_ALL_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case Types.CREDIT_NOTES_EDIT_REQUEST:
    case Types.CREDIT_NOTES_CREATION_REQUEST:
      return {
        ...state,
        creation: {
          loading: true,
          error: null,
        },
      };
    case Types.CREDIT_NOTES_CREATION_REQUEST_SUCCESS:
      return {
        ...state,
        creation: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case Types.CREDIT_NOTES_EDIT_SUCCESS:
      return {
        ...state,
        creation: {
          loading: false,
          error: null,
        },
        data: state.data.map(d => (d.id !== action.payload.id ? d : { ...d, ...action.payload })),
      };
    case Types.CREDIT_NOTES_EDIT_FAILURE:
    case Types.CREDIT_NOTES_CREATION_REQUEST_SUCCESS_FAILURE:
      return {
        ...state,
        creation: {
          loading: false,
          error: action.payload,
        },
      };
    case Types.CREDIT_NOTES_DELETE_REQUEST:
      return {
        ...state,
        deletion: {
          error: null,
          loading: true,
        },
      };
    case Types.CREDIT_NOTES_DELETE_SUCCESS:
      return {
        ...state,
        deletion: {
          error: null,
          loading: false,
        },
        data: state.data.filter(a => a.id !== action.payload),
      };
    case Types.CREDIT_NOTES_DELETE_FAILURE:
      return {
        ...state,
        deletion: {
          error: action.payload,
          loading: false,
        },
      };
    case Types.CREDIT_NOTES_GENERATE_REGULAR_CSV_REQUEST:
      return {
        ...state, regularCsv: { loading: true, error: null },
      };
    case Types.CREDIT_NOTES_GENERATE_REGULAR_CSV_SUCCESS: {
      return {
        ...state, regularCsv: { data: action.payload, loading: false, error: null },
      }; }
    case Types.CREDIT_NOTES_GENERATE_REGULAR_CSV_FAILURE:
      return { ...state, regularCsv: { loading: false, error: action.payload } };
    case Types.CREDIT_NOTES_GENERATE_XERO_CSV_REQUEST:
      return {
        ...state, xeroCsv: { loading: true, error: null },
      };
    case Types.CREDIT_NOTES_GENERATE_XERO_CSV_SUCCESS: {
      return {
        ...state, xeroCsv: { data: action.payload, loading: false, error: null },
      }; }
    case Types.CREDIT_NOTES_GENERATE_XERO_CSV_FAILURE:
      return { ...state, xeroCsv: { loading: false, error: action.payload } };
    case Types.CREDIT_NOTES_GENERATE_COLPPY_CSV_REQUEST:
      return {
        ...state, colppyCsv: { loading: true, error: null },
      };
    case Types.CREDIT_NOTES_GENERATE_COLPPY_CSV_SUCCESS: {
      return {
        ...state, colppyCsv: { data: action.payload, loading: false, error: null },
      }; }
    case Types.CREDIT_NOTES_GENERATE_COLPPY_CSV_FAILURE:
      return { ...state, colppyCsv: { loading: false, error: action.payload } };
    default:
      return state;
  }
}
