import { discountReasonsReducer } from './discountReason.reducer';
import * as actions from './discountReason.actions';
import sagas from './discountReason.sagas';
import * as selector from './discountReason.selector';

export default {
  reducer: discountReasonsReducer,
  actions,
  sagas,
  selector,
};
