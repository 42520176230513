import { Types as AdvertiserTypes } from 'store/advertisers/advertisers.actions';
import { Types as BillingCenterTypes } from 'store/billingCenters/billingCenter.actions';
import { Types as AdAccountTypes } from 'store/adAccount/adAccount.actions';
import { Types as PaymentMethodTypes } from 'store/paymentMethod/paymentMethod.actions';
import { Types as AdjustmentTypes } from 'store/adjustments/adjustments.actions';
import { Types as InvoiceTypes } from 'store/invoice/invoice.actions';
import { Types as CreditNotesTypes } from 'store/creditNotes/creditNotes.actions';
import { Types as EndUserTypes } from 'store/endUser/endUser.actions';
import { Types as NotificationTypes } from './notifications.actions';
import { Messages } from './notifications.messages';

export const INITIAL_STATE = {
  type: null,
  message: null,
  isPermanent: false,
};

export function notificationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AdvertiserTypes.ADVERTISER_REQUEST_FAILURE: {
      return {
        type: 'error',
        message: Messages.ADVERTISER_FETCH_ADVERTISERS_ERROR,
      };
    }
    case BillingCenterTypes.BILLING_CENTER_DELETE_SUCCESS: {
      return {
        type: 'success',
        message: Messages.DELETE_BILLING_CENTER_SUCCESS.replace('@name', action.payload.name),
      };
    }
    case BillingCenterTypes.BILLING_CENTER_DELETE_FAILURE: {
      const msg = Messages[action.payload.error] || Messages.DELETE_BILLING_CENTER_ERROR;
      const bcName = action.payload.name;
      return {
        message: msg.replace('@name', bcName || ''),
        type: 'error',
      }; }
    case BillingCenterTypes.BILLING_CENTER_CREATE_BILLING_CENTER_SUCCESS: {
      const bc = action.payload;
      return {
        message: Messages.CREATE_BILLING_CENTER_SUCCESS.replace('@name', bc ? bc.name : ' '),
        type: 'success',
      };
    }
    case BillingCenterTypes.BILLING_CENTER_CREATE_BILLING_CENTER_FAILURE: {
      const msg = Messages[action.payload] || Messages.CREATE_BILLING_CENTER_ERROR;
      return { message: msg, type: 'error' }; }
    case BillingCenterTypes.BILLING_CENTER_EDIT_BILLING_CENTER_SUCCESS: {
      const bc = action.payload;
      return {
        message: Messages.EDIT_BILLING_CENTER_SUCCESS.replace('@name', bc ? bc.name : ' '),
        type: 'success',
      }; }
    case BillingCenterTypes.BILLING_CENTER_EDIT_BILLING_CENTER_FAILURE: {
      const msg = Messages[action.payload] || Messages.CREATE_BILLING_CENTER_ERROR;
      return { type: 'error', message: msg };
    }
    case AdAccountTypes.AD_ACCOUNT_FETCH_ALL_FAILURE: {
      return {
        message: Messages.FETCH_ADACCOUNT_ERRROR,
        type: 'error',
      };
    }
    case AdAccountTypes.AD_ACCOUNT_CREATE_AD_ACCOUNT_SUCCESS: {
      const adAccount = action.payload;
      return {
        message: Messages.CREATE_ADACCOUNT_SUCCESS.replace('@name', adAccount ? adAccount.accountName : ' '),
        type: 'success',
      };
    }
    case AdAccountTypes.AD_ACCOUNT_CREATE_AD_ACCOUNT_FAILURE: {
      const msg = Messages[action.payload] || Messages.CREATE_ADACCOUNT_ERROR;
      return {
        message: msg,
        type: 'error',
      };
    }
    case AdAccountTypes.AD_ACCOUNT_EDIT_AD_ACCOUNT_SUCCESS: {
      const adAccount = action.payload;
      return {
        message: Messages.EDIT_AD_ACCOUNT_SUCCESS.replace('@name', adAccount ? adAccount.accountName : ' '),
        type: 'success',
      };
    }
    case AdAccountTypes.AD_ACCOUNT_EDIT_AD_ACCOUNT_FAILURE: {
      const msg = Messages[action.payload] || Messages.CREATE_ERROR;
      return { message: msg, type: 'error' };
    }
    case AdAccountTypes.AD_ACCOUNT_DELETE_AD_ACCOUNT_SUCCESS: {
      const adAccountName = action.payload.accountName;
      return {
        message: Messages.DELETE_AD_ACCOUNT_SUCCESS.replace('@name', adAccountName),
        type: 'success',
      };
    }
    case AdAccountTypes.AD_ACCOUNT_DELETE_AD_ACCOUNT_FAILURE: {
      const msg = Messages[action.payload.error] || Messages.DELETE_AD_ACCOUNT_ERROR;
      const adAccountName = action.payload.accountName;
      return {
        message: msg.replace('@name', adAccountName || ' '),
        type: 'error',
      };
    }
    case PaymentMethodTypes.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_SUCCESS: {
      return {
        message: Messages.CREATE_PAYMENT_METHOD_SUCCESS,
        type: 'success',
      };
    }
    case CreditNotesTypes.CREDIT_NOTES_CREATION_REQUEST_SUCCESS: {
      return {
        message: Messages.CREATE_CREDIT_NOTE_SUCCESS,
        type: 'success',
      };
    }
    case CreditNotesTypes.CREDIT_NOTES_CREATION_REQUEST_FAILURE: {
      const message = Messages[action.payload.error] || Messages.CREATE_CREDIT_NOTE_ERROR;
      return {
        message,
        type: 'error',
      };
    }
    case CreditNotesTypes.CREDIT_NOTES_DELETE_SUCCESS: {
      return {
        message: Messages.CREDIT_NOTE_DELETE_SUCCESS,
        type: 'success',
      };
    }
    case CreditNotesTypes.CREDIT_NOTES_DELETE_FAILURE: {
      const message = Messages[action.payload.error] || Messages.CREDIT_NOTE_DELETE_ERROR;
      return {
        message,
        type: 'error',
      };
    }
    case CreditNotesTypes.CREDIT_NOTES_EDIT_SUCCESS: {
      return {
        message: Messages.CREDIT_NOTE_EDIT_SUCCESS,
        type: 'success',
      };
    }
    case CreditNotesTypes.CREDIT_NOTES_EDIT_FAILURE: {
      const message = Messages[action.payload.error] || Messages.CREDIT_NOTE_EDIT_ERROR;
      return {
        message,
        type: 'error',
      };
    }
    case PaymentMethodTypes.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_FAILURE: {
      const msg = Messages[action.payload] || Messages.CREATE_PAYMENT_METHOD_ERROR;
      return {
        message: msg,
        type: 'error',
      }; }
    case AdjustmentTypes.CREATE_ADJUSTMENTS_SUCCESS: {
      return {
        message: Messages.CREATE_ADJUSTMENT_SUCCESS,
        type: 'success',
      };
    }
    case AdjustmentTypes.CREATE_ADJUSTMENTS_FAILURE: {
      const msg = Messages[action.payload] || Messages.CREATE_ADJUSTMENT_ERROR;
      return {
        message: msg,
        type: 'error',
      };
    }
    case AdjustmentTypes.EDIT_ADJUSTMENT_SUCCESS: {
      return {
        message: Messages.EDIT_ADJUSTMENT_SUCCESS,
        type: 'success',
      };
    }
    case AdjustmentTypes.EDIT_ADJUSTMENT_FAILURE: {
      const msg = Messages[action.payload] || Messages.CREATE_ADJUSTMENT_ERROR;
      return {
        message: msg,
        type: 'error',
      };
    }
    case AdjustmentTypes.DELETE_ADJUSTMENT_SUCCESS: {
      return {
        message: Messages.DELETE_ADJUSTMENT_SUCCESS,
        type: 'success',
      };
    }
    case AdjustmentTypes.DELETE_ADJUSTMENT_FAILURE: {
      return {
        message: Messages.DELETE_ADJUSTMENT_FAILURE,
        type: 'error',
      };
    }
    case InvoiceTypes.INVOICES_FETCH_ALL_REQUEST_FAILURE: {
      const msg = Messages[action.payload] || Messages.FETCH_INVOICE_ERROR;
      return {
        message: msg,
        type: 'error',
      };
    }
    case InvoiceTypes.INVOICES_EDIT_FAILURE: {
      const msg = Messages[action.payload] || Messages.EDIT_INVOICE_ERROR;
      return {
        message: msg,
        type: 'error',
      };
    }
    case InvoiceTypes.INVOICES_GENERATE_XERO_CSV_FAILURE: {
      const msg = Messages[action.payload] || Messages.DOWNLOAD_CSV_ERROR;
      return {
        message: msg,
        type: 'error',
      }; }
    case InvoiceTypes.INVOICES_GENERATE_COLPPY_CSV_FAILURE: {
      const msg = Messages[action.payload] || Messages.DOWNLOAD_CSV_ERROR;
      return {
        message: msg,
        type: 'error',
      }; }
    case InvoiceTypes.INVOICES_GENERATE_REGULAR_CSV_FAILURE: {
      const msg = Messages[action.payload] || Messages.DOWNLOAD_CSV_ERROR;
      return {
        message: msg,
        type: 'error',
      }; }
    case CreditNotesTypes.CREDIT_NOTES_GENERATE_REGULAR_CSV_FAILURE: {
      const msg = Messages[action.payload] || Messages.DOWNLOAD_CSV_ERROR;
      return {
        message: msg,
        type: 'error',
      }; }
    case CreditNotesTypes.CREDIT_NOTES_GENERATE_XERO_CSV_FAILURE: {
      const msg = Messages[action.payload] || Messages.DOWNLOAD_CSV_ERROR;
      return {
        message: msg,
        type: 'error',
      }; }
    case CreditNotesTypes.CREDIT_NOTES_GENERATE_COLPPY_CSV_FAILURE: {
      const msg = Messages[action.payload] || Messages.DOWNLOAD_CSV_ERROR;
      return {
        message: msg,
        type: 'error',
      }; }
    case EndUserTypes.END_USER_FETCH_OVERVIEW_SPEND_FAILURE: {
      const msg = Messages[action.payload] || Messages.END_USER_FETCH_SPEND_DATA_ERROR;
      return {
        message: msg,
        type: 'error',
      }; }
    case EndUserTypes.END_USER_FETCH_OVERVIEW_INVOICES_FAILURE: {
      const msg = Messages[action.payload] || Messages.END_USER_FETCH_INVOICE_DATA_ERROR;
      return {
        message: msg,
        type: 'error',
      };
    }
    case NotificationTypes.NOTIFICATION_RESET: {
      return {
        message: null,
        type: null,
      };
    }
    case NotificationTypes.NOTIFICATION_ERROR_NOTIFICATION: {
      return {
        message: action.payload.message,
        isPermanent: action.payload.isPermanent,
        type: 'error',
      };
    }
    default:
      return state;
  }
}
