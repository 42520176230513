import { Types } from './adAccount.actions';

export const INITIAL_STATE = {
  list: {
    loading: true,
    error: null,
    currentAdAccount: null,
  },
  toggleStatus: {
    loading: false,
    success: false,
    error: null,
  },
  creation: {
    loading: true,
    error: null,
  },
  current: {
    loading: true,
    error: null,
  },
  edition: {
    loading: true,
    error: null,
  },
  delete: {
    loading: false,
    success: false,
    error: null,
    affectedId: -1,
  },
};

export function adAccountReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.AD_ACCOUNT_FETCH_ALL_REQUEST:
      return {
        ...state,
        list: {
          ...(action.temp ? state.list : {}), loading: true, error: null, currentAdAccount: null,
        },
      };
    case Types.AD_ACCOUNT_FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.temp ? 'temp' : 'data']: action.payload,
          currentAdAccount: action.temp ? state.list.currentAdAccount : action.payload[0],
          loading: false,
        },
      };
    case Types.AD_ACCOUNT_FETCH_ALL_FAILURE:
      return { ...state, list: { ...state.list, error: action.payload, loading: false } };
    case Types.AD_ACCOUNT_SELECT_CURRENT_ENDUSER:
      return {
        ...state,
        list: { ...state.list, currentAdAccount: action.payload },
      };
    case Types.AD_ACCOUNT_FETCH_ONE_REQUEST:
      return { ...state, current: { loading: true, error: null } };
    case Types.AD_ACCOUNT_FETCH_ONE_SUCCESS:
      return { ...state, current: { ...state.list, data: action.payload, loading: false } };
    case Types.AD_ACCOUNT_FETCH_ONE_FAILURE:
      return { ...state, current: { ...state.list, error: action.payload, loading: false } };
    case Types.AD_ACCOUNT_CREATE_AD_ACCOUNT_REQUEST:
      return { ...state, creation: { loading: true, error: null } };
    case Types.AD_ACCOUNT_CREATE_AD_ACCOUNT_SUCCESS:
      return { ...state, creation: { ...state.creation, data: action.payload, loading: false } };
    case Types.AD_ACCOUNT_CREATE_AD_ACCOUNT_FAILURE:
      return { ...state, creation: { ...state.creation, error: action.payload, loading: false } };
    case Types.AD_ACCOUNT_CREATE_AD_ACCOUNT_RESET:
      return {
        ...state,
        creation: { error: action.payload, loading: false },
      };
    case Types.AD_ACCOUNT_EDIT_AD_ACCOUNT_REQUEST:
      return { ...state, edition: { loading: true, error: null }, current: { loading: true, error: null } };
    case Types.AD_ACCOUNT_EDIT_AD_ACCOUNT_SUCCESS: {
      return {
        ...state,
        edition: {
          ...state.edition,
          data: action.payload,
          loading: false,
        },
      }; }
    case Types.AD_ACCOUNT_EDIT_AD_ACCOUNT_FAILURE: {
      return { ...state, edition: { ...state.edition, loading: false } };
    }
    case Types.AD_ACCOUNT_EDIT_AD_ACCOUNT_RESET:
      return {
        ...state,
        edition: {
          ...state.edition, data: null, error: action.payload, loading: false,
        },
      };
    case Types.AD_ACCOUNT_DELETE_AD_ACCOUNT_REQUEST:
      return {
        ...state,
        delete: {
          loading: true, error: null, success: null, affectedId: action.payload,
        },
      };
    case Types.AD_ACCOUNT_DELETE_AD_ACCOUNT_SUCCESS: {
      return {
        ...state,
        delete: {
          ...state.delete,
          data: state.list.data.filter(a => a.id !== action.payload.id),
          error: null,
          loading: false,
        },
        list: { data: state.list.data.filter(a => a.id !== action.payload.id) },
      };
    }
    case Types.AD_ACCOUNT_DELETE_AD_ACCOUNT_FAILURE: {
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    }
    case Types.AD_ACCOUNT_DELETE_AD_ACCOUNT_RESET:
      return {
        ...state,
        delete: {
          loading: false, error: null, success: null, affectedId: -1,
        },
      };
    case Types.AD_ACCOUNT_TOGGLE_STATUS_REQUEST:
      return { ...state, toggleStatus: { loading: true, success: false, error: null } };
    case Types.AD_ACCOUNT_TOGGLE_STATUS_SUCCESS:
      return {
        ...state,
        toggleStatus: { loading: false, success: true, error: null },
        list: {
          ...state.list,
          data: state.list.data.map(adAccount => (adAccount.id === action.payload
            ? { ...adAccount, isDeliveringAds: !adAccount.isDeliveringAds }
            : adAccount
          )),
        },
      };
    case Types.AD_ACCOUNT_TOGGLE_STATUS_FAILURE:
      return { ...state, toggleStatus: { loading: false, success: false, error: action.payload } };
    default:
      return state;
  }
}
