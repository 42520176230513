import { adAccountTypesReducer } from './adAccountTypes.reducer';
import * as actions from './adAccountTypes.actions';
import sagas from './adAccountTypes.sagas';
import * as selector from './adAccountTypes.selector';

export default {
  reducer: adAccountTypesReducer,
  actions,
  sagas,
  selector,
};
