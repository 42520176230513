import { Types } from './adjustments.actions';

export const INITIAL_STATE = {
  adjustments: [],
  loading: false,
  creation: {
    loading: true,
    error: null,
  },
  edition: {
    loading: true,
    error: null,
  },
  delete: {
    loading: false,
    error: null,
  },
};

export function adjustmentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADJUSTMENTS_REQUEST:
      return {
        ...state, loading: true, error: null, adjustments: [],
      };
    case Types.ADJUSTMENTS_REQUEST_SUCCESS: {
      return {
        ...state, loading: false, error: null, adjustments: [...action.payload],
      }; }
    case Types.ADJUSTMENTS_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case Types.CREATE_ADJUSTMENTS_REQUEST:
      return { ...state, creation: { loading: true, error: null } };
    case Types.CREATE_ADJUSTMENTS_SUCCESS:
      return {
        ...state,
        creation: { ...state.creation, data: action.payload, loading: false },
        adjustments: [...state.adjustments, action.payload],
      };
    case Types.CREATE_ADJUSTMENTS_FAILURE:
      return { ...state, creation: { ...state.creation, error: action.payload, loading: false } };
    case Types.CREATE_ADJUSTMENTS_RESET:
      return {
        ...state,
        creation: { error: action.payload, loading: false },
      };
    case Types.EDIT_ADJUSTMENT_SUCCESS: {
      return {
        ...state,
        edition: {
          data: action.payload,
          loading: false,
        },
        adjustments: state.adjustments.map(s => {
          if (s.id !== parseInt(action.payload.id, 10)) {
            return s;
          }
          return action.payload;
        }),
      };
    }
    case Types.EDIT_ADJUSTMENT_FAILURE: {
      return { ...state, edition: { error: action.payload, loading: false } }; }
    case Types.EDIT_ADJUSTMENT_RESET:
      return {
        ...state,
        edit: {
          ...state.edit, loading: false, error: null, data: null,
        },
      };
    case Types.DELETE_ADJUSTMENT_REQUEST: {
      return {
        ...state,
        delete: {
          loading: true, error: null, success: null, affectedId: action.payload,
        },
      };
    }
    case Types.DELETE_ADJUSTMENT_SUCCESS: {
      return {
        ...state,
        delete: {
          ...state.delete,
          data: state.adjustments.filter(a => a.id !== action.payload.id),
          error: null,
          loading: false,
        },
        adjustments: state.adjustments.filter(a => a.id !== action.payload.id),
      };
    }
    case Types.DELETE_ADJUSTMENT_FAILURE: {
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
}
