import { Types } from './discountReason.actions';

export const INITIAL_STATE = {
  discountReasons: [],
  loading: true,
};

export function discountReasonsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.DISCOUNTREASON_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.DISCOUNTREASON_REQUEST_SUCCESS:
      return {
        ...state, loading: false, error: null, discountReasons: [...action.payload],
      };
    case Types.DISCOUNTREASON_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
