import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import {
  Types,
  fetchAllAdAccounts,
  createAdAccount,
  deleteAdAccount,
  toggleStatusAdAccount,
  fetchSingleAdAccount,
  editAdAccount,
} from './adAccount.actions';

// Errors
import AdAccountError from './adAccount.error';

// Selectors
import { adAccountsList } from './adAccount.selector';

const BASE_ENDPOINT = '/adAccount';

const fetchList = async (advertiserData, country) => {
  const params = [];

  if (country) {
    params.push(`country=${country}`);
  }

  if (advertiserData) {
    if (Array.isArray(advertiserData)) {
      if (advertiserData.length > 0) {
        params.push(`advertiser=${advertiserData.join(',')}`);
      }
    } else {
      params.push(`advertiser=${advertiserData}`);
    }
  }

  return api.get(`${BASE_ENDPOINT}${params.length > 0 ? `?${params.join('&')}` : ''}`);
};
const fetchSingle = async adAccountId => api.get(`${BASE_ENDPOINT}/${adAccountId}`);
const create = async newAdAccountData => api.post(`${BASE_ENDPOINT}`, newAdAccountData);
const update = async (editedAdAccountData, adAccountId) => api
  . patch(`${BASE_ENDPOINT}/${adAccountId}`, editedAdAccountData);
const remove = async adAccountId => api.delete(`${BASE_ENDPOINT}/${adAccountId}`);
const toggleStatus = async adAccountId => api.patch(`${BASE_ENDPOINT}/isDeliveringAds/${adAccountId}`);

function* fetchAdAccountsList({ payload: advertiserId, temp, country }) {
  let result;
  try {
    if (temp && advertiserId) {
      const currentList = yield select(adAccountsList);
      if (currentList && currentList.length > 0) {
        const advertiserIdString = `${advertiserId}`;
        const filterByAdvertiser = currentList.filter(a => advertiserIdString.includes(a.AdvertiserId));
        if (filterByAdvertiser && filterByAdvertiser.length > 0) {
          return yield put(fetchAllAdAccounts.success(filterByAdvertiser, temp));
        }
      }
    }
    result = yield call(fetchList, advertiserId, country);
    if (result && result.data) {
      return yield put(fetchAllAdAccounts.success(result.data, temp));
    }
    return yield put(fetchAllAdAccounts.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : AdAccountError[data.code] || data.code;
      return yield put(fetchAllAdAccounts.failure(error));
    }
    return yield put(fetchAllAdAccounts.failure(message));
  }
}

function* fetchAdAccount({ payload: adAccountId }) {
  let result;

  try {
    result = yield call(fetchSingle, adAccountId);

    if (result && result.data) {
      return yield put(fetchSingleAdAccount.success(result.data));
    }
    return yield put(fetchSingleAdAccount.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : AdAccountError[data.code] || data.code;
      return yield put(fetchSingleAdAccount.failure(error));
    }
    return yield put(fetchSingleAdAccount.failure(message));
  }
}

function* updateAdAccount({ payload: { adAccountData, adAccountId } }) {
  let result;
  const validatedAdAccount = { ...adAccountData };
  if (adAccountData.adAgency && adAccountData.adAgency.id) delete validatedAdAccount.adAgency.id;
  if (adAccountData.politicalSponsoringEntity && adAccountData.politicalSponsoringEntity.id) {
    delete validatedAdAccount.politicalSponsoringEntity.id;
  }

  try {
    result = yield call(update, validatedAdAccount, adAccountId);

    if (result && result.data) {
      return yield put(editAdAccount.success(result.data));
    }
    return yield put(editAdAccount.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : AdAccountError[data.code] || data.code;
      return yield put(editAdAccount.failure(error));
    }
    return yield put(editAdAccount.failure(message));
  }
}

function* createNewAdAccount({ payload }) {
  let result;
  try {
    result = yield call(create, payload);

    if (result && result.data) {
      return yield put(createAdAccount.success(result.data));
    }
    return yield put(createAdAccount.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : AdAccountError[data.code] || data.code;
      return yield put(createAdAccount.failure(error));
    }
    return yield put(createAdAccount.failure(message));
  }
}

function* deleteAdAccountSaga({ payload }) {
  let response;
  try {
    response = yield call(remove, payload.id);
    if (response.status === 200) {
      return yield put(deleteAdAccount.success(payload));
    }
    return yield put(deleteAdAccount.failure('Unknown Error'));
  } catch (error) {
    return yield put(deleteAdAccount.failure({ error, name: payload.accountName }));
  }
}

function* toggleStatusSaga({ payload }) {
  let response;
  try {
    response = yield call(toggleStatus, payload);
    if (response.status === 200) {
      return yield put(toggleStatusAdAccount.success(payload));
    }
    return yield put(toggleStatusAdAccount.failure('Unknown Error'));
  } catch (error) {
    return yield put(toggleStatusAdAccount.failure(error.message));
  }
}

export default [
  takeLatest(Types.AD_ACCOUNT_FETCH_ALL_REQUEST, fetchAdAccountsList),
  takeLatest(Types.AD_ACCOUNT_CREATE_AD_ACCOUNT_REQUEST, createNewAdAccount),
  takeLatest(Types.AD_ACCOUNT_DELETE_AD_ACCOUNT_REQUEST, deleteAdAccountSaga),
  takeLatest(Types.AD_ACCOUNT_TOGGLE_STATUS_REQUEST, toggleStatusSaga),
  takeLatest(Types.AD_ACCOUNT_FETCH_ONE_REQUEST, fetchAdAccount),
  takeLatest(Types.AD_ACCOUNT_EDIT_AD_ACCOUNT_REQUEST, updateAdAccount),
];
