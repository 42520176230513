import { takeLatest, put, call } from 'redux-saga/effects';

// Services
import { api } from 'services/api';

// Types
import { Types, fetchAllPaymentSettingsTypes } from './paymentSettingsTypes.actions';

const BASE_ENDPOINT = '/paymentSettingsType';

const findAllRequest = async () => api.get(BASE_ENDPOINT);

function* fetchPaymentSettingsTypes() {
  let result;

  try {
    result = yield call(findAllRequest);

    if (result && result.data) {
      return yield put(fetchAllPaymentSettingsTypes.success(result.data));
    }
    return yield put(fetchAllPaymentSettingsTypes.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(fetchAllPaymentSettingsTypes.failure(error));
    }
    return yield put(fetchAllPaymentSettingsTypes.failure(message));
  }
}

export default [
  takeLatest(Types.PAYMENT_SETTINGS_TYPES_FETCH_ALL_REQUEST, fetchPaymentSettingsTypes),
];
