import { takeLatest, put } from 'redux-saga/effects';

// API
import {
  api, goToLogin, LoginPath, isJamppDomain,
} from 'services/api';

// Types
import { Types, login, logout } from './auth.actions';

// Errors
import AuthError from './auth.error';

const BASE_ENDPOINT = '/auth';

function* loginRequest({ payload }) {
  let result;
  try {
    result = yield api.post(`${BASE_ENDPOINT}/login`, payload);

    if (result && result.data) {
      return yield put(login.success(result.data));
    }
    return yield put(login.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : AuthError[data.code] || data.code;
      return yield put(login.failure(error));
    }
    return yield put(login.failure(message));
  }
}

function* logoutRequest() {
  let result;
  try {
    if (isJamppDomain) {
      window.location.href = `${LoginPath}/logout`;
      return yield put(logout.success({ status: 'OK' }));
    }
    result = yield api.get(`${BASE_ENDPOINT}/logout`);
    if (result && result.data) {
      goToLogin();
      return yield put(logout.success(result.data));
    }
    return yield put(logout.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : AuthError[data.code] || data.code;
      return yield put(logout.failure(error));
    }
    return yield put(logout.failure(message));
  }
}

export default [
  takeLatest(Types.LOGIN_REQUEST, loginRequest),
  takeLatest(Types.LOGOUT_REQUEST, logoutRequest),
];
