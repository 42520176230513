import { takeLatest, put } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import { Types, countries } from './countries.actions';

const BASE_ENDPOINT = '/country';

function* CountriesRequest() {
  try {
    const result = yield api.get(BASE_ENDPOINT);
    if (result && result.data) {
      return yield put(countries.success(result.data));
    }
    return yield put(countries.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(countries.failure(error));
    }
    return yield put(countries.failure(message));
  }
}

export default [
  takeLatest(Types.COUNTRIES_REQUEST, CountriesRequest),
];
