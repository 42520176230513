import { invoiceReducer } from './invoice.reducer';
import * as actions from './invoice.actions';
import sagas from './invoice.sagas';
import * as selector from './invoice.selector';
import * as enums from './invoice.enum';

export default {
  reducer: invoiceReducer,
  actions,
  sagas,
  selector,
  enums,
};
