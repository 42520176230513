import { Types } from './taxRateNames.actions';

export const INITIAL_STATE = {
  taxRateNames: [],
  loading: false,
};

export function taxRateNamesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.TAXRATENAMES_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.TAXRATENAMES_REQUEST_SUCCESS: {
      return {
        ...state, loading: false, error: null, taxRateNames: [...action.payload],
      }; }
    case Types.TAXRATENAMES_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
