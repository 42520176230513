import { Types } from './billingCenter.actions';

export const INITIAL_STATE = {
  billingCenter: [],
  loading: true,
  delete: {
    loading: false,
    success: false,
    error: null,
    affectedId: -1,
  },
  creation: {
    loading: false,
    error: null,
    data: {},
  },
  billingCenterById: {
    loading: false,
    error: null,
    billingCenter: {},
  },
  edit: {
    loading: false,
    error: null,
    billingCenterEdit: {},
  },
};

export function billingCentersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.BILLING_CENTER_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.BILLING_CENTER_REQUEST_SUCCESS: {
      return {
        ...state, loading: false, error: null, billingCenters: [...action.payload],
      }; }
    case Types.BILLING_CENTER_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case Types.BILLING_CENTER_REQUESTBYID: {
      return { ...state, loading: true, error: null }; }
    case Types.BILLING_CENTER_REQUESTBYID_SUCCESS:
      return {
        ...state, billingCenterById: { loading: false, error: null, billingCenter: { ...action.payload } },
      };
    case Types.BILLING_CENTER_REQUESTBYID_RESET: {
      return {
        ...state,
        billingCenterById: {
          loading: false, error: null, billingCenter: {},
        },
      }; }
    case Types.BILLING_CENTER_REQUESTBYID_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case Types.BILLING_CENTER_DELETE_RESET:
      return {
        ...state,
        delete: {
          loading: false, error: null, success: null, affectedId: -1,
        },
      };
    case Types.BILLING_CENTER_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          loading: true, error: null, success: null, affectedId: action.payload,
        },
      };
    case Types.BILLING_CENTER_DELETE_SUCCESS: {
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
        billingCenters: state.billingCenters.filter(b => b.id !== action.payload.id),
      };
    }
    case Types.BILLING_CENTER_DELETE_FAILURE: {
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    }
    case Types.BILLING_CENTER_CREATE_BILLING_CENTER_REQUEST:
      return { ...state, creation: { loading: true, error: null } };
    case Types.BILLING_CENTER_CREATE_BILLING_CENTER_SUCCESS: {
      return {
        ...state,
        creation: {
          data: action.payload,
          loading: false,
        },
      }; }
    case Types.BILLING_CENTER_CREATE_BILLING_CENTER_FAILURE: {
      return { ...state, creation: { loading: false } }; }
    case Types.BILLING_CENTER_CREATE_RESET:
      return {
        ...state,
        creation: {
          loading: false, error: null, data: {},
        },
      };
    case Types.BILLING_CENTER_EDIT_BILLING_CENTER_REQUEST:
      return { ...state, edit: { loading: true, error: null } };
    case Types.BILLING_CENTER_EDIT_BILLING_CENTER_SUCCESS: {
      return {
        ...state,
        edit: {
          billingCenterEdit: action.payload,
          loading: false,
        },
      }; }
    case Types.BILLING_CENTER_EDIT_BILLING_CENTER_FAILURE: {
      return { ...state, edit: { error: action.payload, loading: false } }; }
    case Types.BILLING_CENTER_EDIT_RESET:
      return {
        ...state,
        edit: {
          ...state.edit, loading: false, error: null, billingCenterEdit: null,
        },
      };
    default:
      return state;
  }
}
