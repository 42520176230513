import { taxIdTypeReducer } from './taxIdType.reducer';
import * as actions from './taxIdType.actions';
import sagas from './taxIdType.sagas';
import * as selector from './taxIdType.selector';

export default {
  reducer: taxIdTypeReducer,
  actions,
  sagas,
  selector,
};
