import { takeLatest, put, call } from 'redux-saga/effects';

// Services
import { api } from 'services/api';

// Helpers
import { generateQueryString } from 'utils/helpers';

// Types
import {
  Types, fetchAllPaymentMethod, createPaymentMethod, makeDefaultPaymentMethod,
} from './paymentMethod.actions';

// Errors
import PaymentMethodError from './paymentMethod.error';

const BASE_ENDPOINT = '/paymentMethod';

const findAllRequest = async (advertiserId = '', adAccountId = '') => {
  const url = generateQueryString(`${BASE_ENDPOINT}`,
    { advertiser: advertiserId.toString(), adAccount: adAccountId.toString() });
  return api.get(url);
};
const create = async newPaymentMethodData => api.post(`${BASE_ENDPOINT}`, newPaymentMethodData);
const makeDefault = async paymentMethodId => api.patch(`${BASE_ENDPOINT}/makeDefault/${paymentMethodId}`);

function* fetchPaymentMethods({ payload }) {
  let result;

  try {
    result = yield call(findAllRequest, payload.advertiserId, payload.adAccountId);

    if (result && result.data) {
      return yield put(fetchAllPaymentMethod.success(result.data));
    }
    return yield put(fetchAllPaymentMethod.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(fetchAllPaymentMethod.failure(error));
    }
    return yield put(fetchAllPaymentMethod.failure(message));
  }
}

function* makeDefaultPaymentMethodForAdAccount({ payload: paymentMethodId }) {
  let result;

  try {
    result = yield call(makeDefault, paymentMethodId);

    if (result && result.data) {
      return yield put(makeDefaultPaymentMethod.success(result.data));
    }
    return yield put(makeDefaultPaymentMethod.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(makeDefaultPaymentMethod.failure(error));
    }
    return yield put(makeDefaultPaymentMethod.failure(message));
  }
}

function* createNewPaymentMethod({ payload }) {
  let result;

  try {
    result = yield call(create, payload);

    if (result && result.data) {
      return yield put(createPaymentMethod.success(result.data));
    }
    return yield put(createPaymentMethod.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : PaymentMethodError[data.code] || data.code;
      return yield put(createPaymentMethod.failure(error));
    }
    return yield put(createPaymentMethod.failure(message));
  }
}

export default [
  takeLatest(Types.PAYMENT_METHOD_FETCH_ALL_REQUEST, fetchPaymentMethods),
  takeLatest(Types.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_REQUEST, createNewPaymentMethod),
  takeLatest(Types.PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_REQUEST, makeDefaultPaymentMethodForAdAccount),
];
