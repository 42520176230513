import { endUserReducer } from './endUser.reducer';
import * as actions from './endUser.actions';
import sagas from './endUser.sagas';
import * as selector from './endUser.selector';

export default {
  reducer: endUserReducer,
  actions,
  sagas,
  selector,
};
