// Types
export const Types = {
  TAXRATES_REQUEST: 'TAXRATE_REQUEST',
  TAXRATES_REQUEST_SUCCESS: 'TAXRATE_REQUEST_SUCCESS',
  TAXRATES_REQUEST_FAILURE: 'TAXRATE_REQUEST_FAILURE',
};

// Actions
export const taxRates = {
  request: () => ({
    type: Types.TAXRATES_REQUEST,
  }),
  success: payload => ({
    type: Types.TAXRATES_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.TAXRATES_REQUEST_FAILURE,
    payload,
  }),
};
