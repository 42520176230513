import { Types } from './emails.actions';
import { Messages } from './emails.messages';

export const INITIAL_STATE = {
  delete: {
    loading: false,
    success: false,
    error: null,
    affectedId: -1,
  },
};

export function emailsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.EMAILS_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          loading: true, error: null, success: null, affectedId: action.payload,
        },
      };
    case Types.EMAILS_DELETE_SUCCESS: {
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          success: Messages.DELETE_SUCCESS,
        },
      };
    }
    case Types.EMAILS_DELETE_FAILURE: {
      const msg = Messages[action.payload] || Messages.DELETE_ERROR;
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: msg,
        },
      };
    }
    default:
      return state;
  }
}
