import { Types } from './auth.actions';

export const INITIAL_STATE = {
  loading: false,
  token: null,
  refreshToken: null,
  error: null,
};

export function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.LOGIN_REQUEST_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case Types.LOGIN_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
