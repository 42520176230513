import { Types } from './countries.actions';

export const INITIAL_STATE = {
  error: null,
  loading: false,
  data: [],
};

export function countriesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.COUNTRIES_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.COUNTRIES_REQUEST_SUCCESS: {
      return {
        ...state, loading: false, error: null, data: action.payload,
      }; }
    case Types.COUNTRIES_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
