import { taxRateNamesReducer } from './taxRateNames.reducer';
import * as actions from './taxRateNames.actions';
import sagas from './taxRateNames.sagas';
import * as selector from './taxRateNames.selector';

export default {
  reducer: taxRateNamesReducer,
  actions,
  sagas,
  selector,
};
