// Types
export const Types = {
  BILLING_CENTER_REQUEST: 'BILLING_CENTER_REQUEST',
  BILLING_CENTER_REQUEST_SUCCESS: 'BILLING_CENTER_REQUEST_SUCCESS',
  BILLING_CENTER_REQUEST_FAILURE: 'BILLING_CENTER_REQUEST_FAILURE',

  BILLING_CENTER_REQUESTBYID: 'BILLING_CENTER_REQUESTBYID',
  BILLING_CENTER_REQUESTBYID_SUCCESS: 'BILLING_CENTER_REQUESTBYID_SUCCESS',
  BILLING_CENTER_REQUESTBYID_FAILURE: 'BILLING_CENTER_REQUESTBYID_FAILURE',
  BILLING_CENTER_REQUESTBYID_RESET: 'BILLING_CENTER_REQUESTBYID_RESET',

  BILLING_CENTER_DELETE_REQUEST: 'BILLING_CENTER_DELETE_REQUEST',
  BILLING_CENTER_DELETE_SUCCESS: 'BILLING_CENTER_DELETE_SUCCESS',
  BILLING_CENTER_DELETE_FAILURE: 'BILLING_CENTER_DELETE_FAILURE',

  BILLING_CENTER_DELETE_RESET: 'BILLING_CENTER_DELETE_RESET',
  BILLING_CENTER_CREATE_RESET: 'BILLING_CENTER_CREATE_RESET',
  BILLING_CENTER_CREATE_BILLING_CENTER_REQUEST: 'BILLING_CENTER_CREATE_BILLING_CENTER_REQUEST',
  BILLING_CENTER_CREATE_BILLING_CENTER_SUCCESS: 'BILLING_CENTER_CREATE_BILLING_CENTER_SUCCESS',
  BILLING_CENTER_CREATE_BILLING_CENTER_FAILURE: 'BILLING_CENTER_CREATE_BILLING_CENTER_FAILURE',

  BILLING_CENTER_EDIT_BILLING_CENTER_REQUEST: 'BILLING_CENTER_EDIT_BILLING_CENTER_REQUEST',
  BILLING_CENTER_EDIT_BILLING_CENTER_SUCCESS: 'BILLING_CENTER_EDIT_BILLING_CENTER_SUCCESS',
  BILLING_CENTER_EDIT_BILLING_CENTER_FAILURE: 'BILLING_CENTER_EDIT_BILLING_CENTER_FAILURE',
  BILLING_CENTER_EDIT_RESET: 'BILLING_CENTER_EDIT_RESET',

};

// Actions
export const billingCenters = {
  request: payload => ({
    type: Types.BILLING_CENTER_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.BILLING_CENTER_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.BILLING_CENTER_REQUEST_FAILURE,
    payload,
  }),
};

export const billingCenterById = {
  request: payload => ({
    type: Types.BILLING_CENTER_REQUESTBYID,
    payload,
  }),
  success: payload => ({
    type: Types.BILLING_CENTER_REQUESTBYID_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.BILLING_CENTER_REQUESTBYID_FAILURE,
    payload,
  }),
  reset: payload => ({
    type: Types.BILLING_CENTER_REQUESTBYID_RESET,
    payload,
  }),
};

export const deleteBillingCenter = {
  request: payload => ({
    type: Types.BILLING_CENTER_DELETE_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.BILLING_CENTER_DELETE_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.BILLING_CENTER_DELETE_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.BILLING_CENTER_DELETE_RESET,
  }),
};

export const createBillingCenter = {
  request: payload => ({
    type: Types.BILLING_CENTER_CREATE_BILLING_CENTER_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.BILLING_CENTER_CREATE_BILLING_CENTER_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.BILLING_CENTER_CREATE_BILLING_CENTER_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.BILLING_CENTER_CREATE_RESET,
  }),
};

export const editBillingCenter = {
  request: payload => ({
    type: Types.BILLING_CENTER_EDIT_BILLING_CENTER_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.BILLING_CENTER_EDIT_BILLING_CENTER_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.BILLING_CENTER_EDIT_BILLING_CENTER_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.BILLING_CENTER_EDIT_RESET,
  }),
};
