import { notificationsReducer } from './notifications.reducer';
import * as selector from './notifications.selector';
import * as actions from './notifications.actions';
import { Messages } from './notifications.messages';

export default {
  reducer: notificationsReducer,
  selector,
  actions,
  messages: Messages,
};
