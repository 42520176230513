import { Types } from './paymentMethod.actions';

export const INITIAL_STATE = {
  loading: true,
  error: null,
  creation: {
    loading: true,
    error: null,
  },
  defaultEdition: {
    loading: true,
    error: null,
  },
};

export function paymentMethodReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PAYMENT_METHOD_FETCH_ALL_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.PAYMENT_METHOD_FETCH_ALL_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case Types.PAYMENT_METHOD_FETCH_ALL_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case Types.PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_REQUEST:
      return { ...state, defaultEdition: { ...state.defaultEdition, loading: true, error: null } };
    case Types.PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS:
      return { ...state, defaultEdition: { ...state.defaultEdition, data: action.payload, loading: false } };
    case Types.PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_FAILURE:
      return { ...state, defaultEdition: { ...state.defaultEdition, error: action.payload, loading: false } };
    case Types.PAYMENT_METHOD_MAKE_DEFAULT_PAYMENT_METHOD_RESET:
      return { ...state, defaultEdition: { loading: false, error: null } };
    case Types.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_REQUEST:
      return { ...state, creation: { loading: true, error: null } };
    case Types.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        creation: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case Types.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_FAILURE: {
      return {
        ...state,
        creation: { loading: false },
      }; }
    case Types.PAYMENT_METHOD_CREATE_PAYMENT_METHOD_RESET: {
      return {
        ...state,
        creation: {
          data: null, error: null, loading: false,
        },
      };
    }
    default:
      return state;
  }
}
