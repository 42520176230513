import React from 'react';
// import { Helmet } from 'react-helmet';

// ReactRouter
import { Route, Switch } from 'react-router-dom';

// Public Pages
import LoginPage from 'containers/LoginPage';

// Private Pages
import AppLogged from 'containers/AppLogged';

// Components

// Styles
import './App.css';
import { QueryParamProvider } from 'use-query-params';

export const App = React.memo(() => (
  <div className="DragoAPP">
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route component={AppLogged} />
      </Switch>
    </QueryParamProvider>
  </div>
));
