import { statesReducer } from './states.reducer';
import * as actions from './states.actions';
import sagas from './states.sagas';
import * as selector from './states.selector';

export default {
  reducer: statesReducer,
  actions,
  sagas,
  selector,
};
