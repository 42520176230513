// Types
export const Types = {
  ADVERTISER_REQUEST: 'ADVERTISER_REQUEST',
  ADVERTISER_REQUEST_SUCCESS: 'ADVERTISER_REQUEST_SUCCESS',
  ADVERTISER_REQUEST_FAILURE: 'ADVERTISER_REQUEST_FAILURE',
};

// Actions
export const advertisers = {
  request: countryPath => ({
    type: Types.ADVERTISER_REQUEST,
    countryPath,
  }),
  success: payload => ({
    type: Types.ADVERTISER_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.ADVERTISER_REQUEST_FAILURE,
    payload,
  }),
};
