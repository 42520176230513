import { Types } from './advertisers.actions';

export const INITIAL_STATE = {
  advertisers: [],
  loading: true,
  currentAdvertiser: null,
};

export function advertiserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADVERTISER_REQUEST:
      return {
        ...state, loading: true, error: null, currentAdvertiser: null,
      };
    case Types.ADVERTISER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,
        currentAdvertiser: action.payload?.data ? action.payload.data[0] : null,
      };
    case Types.ADVERTISER_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
