import { Types } from './net.actions';

export const INITIAL_STATE = {
  nets: [],
  loading: true,
};

export function netsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NETS_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.NETS_REQUEST_SUCCESS:
      return {
        ...state, loading: false, error: null, nets: [...action.payload],
      };
    case Types.NETS_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
