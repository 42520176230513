// Types
export const Types = {
  INVOICES_FETCH_ALL_REQUEST: 'INVOICES_FETCH_ALL_REQUEST',
  INVOICES_FETCH_ALL_REQUEST_SUCCESS: 'INVOICES_FETCH_ALL_REQUEST_SUCCESS',
  INVOICES_FETCH_ALL_REQUEST_FAILURE: 'INVOICES_FETCH_ALL_REQUEST_FAILURE',
  INVOICES_EDIT_REQUEST: 'INVOICES_EDIT_REQUEST',
  INVOICES_EDIT_SUCCESS: 'INVOICES_EDIT_SUCCESS',
  INVOICES_EDIT_FAILURE: 'INVOICES_EDIT_FAILURE',
  INVOICES_GENERATE_REGULAR_CSV_REQUEST: 'INVOICES_GENERATE_REGULAR_CSV_REQUEST',
  INVOICES_GENERATE_REGULAR_CSV_SUCCESS: 'INVOICES_GENERATE_REGULAR_CSV_SUCCESS',
  INVOICES_GENERATE_REGULAR_CSV_FAILURE: 'INVOICES_GENERATE_REGULAR_CSV_FAILURE',
  INVOICES_GENERATE_XERO_CSV_REQUEST: 'INVOICES_GENERATE_XERO_CSV_REQUEST',
  INVOICES_GENERATE_XERO_CSV_SUCCESS: 'INVOICES_GENERATE_XERO_CSV_SUCCESS',
  INVOICES_GENERATE_XERO_CSV_FAILURE: 'INVOICES_GENERATE_XERO_CSV_FAILURE',
  INVOICES_GENERATE_COLPPY_CSV_REQUEST: 'INVOICES_GENERATE_COLPPY_CSV_REQUEST',
  INVOICES_GENERATE_COLPPY_CSV_SUCCESS: 'INVOICES_GENERATECOLPPY_CSV_SUCCESS',
  INVOICES_GENERATE_COLPPY_CSV_FAILURE: 'INVOICES_GENERATE_COLPPY_CSV_FAILURE',
  INVOICES_GENERATE_CONSOLIDATED_CSV_REQUEST: 'INVOICES_GENERATE_CONSOLIDATED_CSV_REQUEST',
  INVOICES_GENERATE_CONSOLIDATED_CSV_SUCCESS: 'INVOICES_GENERATE_CONSOLIDATED_CSV_SUCCESS',
  INVOICES_GENERATE_CONSOLIDATED_CSV_FAILURE: 'INVOICES_GENERATE_CONSOLIDATED_CSV_FAILURE',
  INVOICES_GENERATE_BR_TXT_REQUEST: 'INVOICES_GENERATE_BR_TXT_REQUEST',
  INVOICES_GENERATE_BR_TXT_SUCCESS: 'INVOICES_GENERATE_BR_TXT_SUCCESS',
  INVOICES_GENERATE_BR_TXT_FAILURE: 'INVOICES_GENERATE_BR_TXT_FAILURE',
};

// Actions
export const fetchInvoices = {
  request: payload => ({
    type: Types.INVOICES_FETCH_ALL_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.INVOICES_FETCH_ALL_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.INVOICES_FETCH_ALL_REQUEST_FAILURE,
    payload,
  }),
};

export const editInvoice = {
  request: payload => ({
    type: Types.INVOICES_EDIT_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.INVOICES_EDIT_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.INVOICES_EDIT_FAILURE,
    payload,
  }),
};

export const generateRegularCsv = {
  request: payload => ({
    type: Types.INVOICES_GENERATE_REGULAR_CSV_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.INVOICES_GENERATE_REGULAR_CSV_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.INVOICES_GENERATE_REGULAR_CSV_FAILURE,
    payload,
  }),
};

export const generateXeroCsv = {
  request: payload => ({
    type: Types.INVOICES_GENERATE_XERO_CSV_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.INVOICES_GENERATE_XERO_CSV_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.INVOICES_GENERATE_XERO_CSV_FAILURE,
    payload,
  }),
};

export const generateColppyCsv = {
  request: payload => ({
    type: Types.INVOICES_GENERATE_COLPPY_CSV_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.INVOICES_GENERATE_COLPPY_CSV_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.INVOICES_GENERATE_COLPPY_CSV_FAILURE,
    payload,
  }),
};

export const generateConsolidatedCsv = {
  request: payload => ({
    type: Types.INVOICES_GENERATE_CONSOLIDATED_CSV_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.INVOICES_GENERATE_CONSOLIDATED_CSV_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.INVOICES_GENERATE_CONSOLIDATED_CSV_FAILURE,
    payload,
  }),
};

export const generateBrazilTXT = {
  request: payload => ({
    type: Types.INVOICES_GENERATE_BR_TXT_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.INVOICES_GENERATE_BR_TXT_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.INVOICES_GENERATE_BR_TXT_FAILURE,
    payload,
  }),
};
