import { jamppBillingOfficesReducer } from './jamppBillingOffice.reducer';
import * as actions from './jamppBillingOffice.actions';
import sagas from './jamppBillingOffice.sagas';
import * as selector from './jamppBillingOffice.selector';

export default {
  reducer: jamppBillingOfficesReducer,
  actions,
  sagas,
  selector,
};
