// Types
export const Types = {
  CREDIT_NOTES_FETCH_ALL_REQUEST: 'CREDIT_NOTES_FETCH_ALL_REQUEST',
  CREDIT_NOTES_FETCH_ALL_REQUEST_SUCCESS: 'CREDIT_NOTES_FETCH_ALL_REQUEST_SUCCESS',
  CREDIT_NOTES_FETCH_ALL_REQUEST_FAILURE: 'CREDIT_NOTES_FETCH_ALL_REQUEST_FAILURE',
  CREDIT_NOTES_CREATION_REQUEST: 'CREDIT_NOTES_CREATION_REQUEST',
  CREDIT_NOTES_CREATION_REQUEST_SUCCESS: 'CREDIT_NOTES_CREATION_REQUEST_SUCCESS',
  CREDIT_NOTES_CREATION_REQUEST_FAILURE: 'CREDIT_NOTES_CREATION_REQUEST_FAILURE',
  CREDIT_NOTES_GENERATE_REGULAR_CSV_REQUEST: 'CREDIT_NOTES_GENERATE_REGULAR_CSV_REQUEST',
  CREDIT_NOTES_GENERATE_REGULAR_CSV_SUCCESS: 'CREDIT_NOTES_GENERATE_REGULAR_CSV_SUCCESS',
  CREDIT_NOTES_GENERATE_REGULAR_CSV_FAILURE: 'CREDIT_NOTES_GENERATE_REGULAR_CSV_FAILURE',
  CREDIT_NOTES_GENERATE_XERO_CSV_REQUEST: 'CREDIT_NOTES_GENERATE_XERO_CSV_REQUEST',
  CREDIT_NOTES_GENERATE_XERO_CSV_SUCCESS: 'CREDIT_NOTES_GENERATE_XERO_CSV_SUCCESS',
  CREDIT_NOTES_GENERATE_XERO_CSV_FAILURE: 'CREDIT_NOTES_GENERATE_XERO_CSV_FAILURE',
  CREDIT_NOTES_GENERATE_COLPPY_CSV_REQUEST: 'CREDIT_NOTES_GENERATE_COLPPY_CSV_REQUEST',
  CREDIT_NOTES_GENERATE_COLPPY_CSV_SUCCESS: 'CREDIT_NOTES_GENERATE_COLPPY_CSV_SUCCESS',
  CREDIT_NOTES_GENERATE_COLPPY_CSV_FAILURE: 'CREDIT_NOTES_GENERATE_COLPPY_CSV_FAILURE',
  CREDIT_NOTES_EDIT_REQUEST: 'CREDIT_NOTES_EDIT_REQUEST',
  CREDIT_NOTES_EDIT_SUCCESS: 'CREDIT_NOTES_EDIT_SUCCESS',
  CREDIT_NOTES_EDIT_FAILURE: 'CREDIT_NOTES_EDIT_FAILURE',
  CREDIT_NOTES_DELETE_REQUEST: 'CREDIT_NOTES_DELETE_REQUEST',
  CREDIT_NOTES_DELETE_SUCCESS: 'CREDIT_NOTES_DELETE_SUCCESS',
  CREDIT_NOTES_DELETE_FAILURE: 'CREDIT_NOTES_DELETE_FAILURE',
};

// Actions
export const fetchCreditNotes = {
  request: payload => ({
    type: Types.CREDIT_NOTES_FETCH_ALL_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CREDIT_NOTES_FETCH_ALL_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CREDIT_NOTES_FETCH_ALL_REQUEST_FAILURE,
    payload,
  }),
};

export const createCreditNote = {
  request: payload => ({
    type: Types.CREDIT_NOTES_CREATION_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CREDIT_NOTES_CREATION_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CREDIT_NOTES_CREATION_REQUEST_FAILURE,
    payload,
  }),
};

export const editCreditNote = { // editCreditNote
  request: payload => ({
    type: Types.CREDIT_NOTES_EDIT_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CREDIT_NOTES_EDIT_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CREDIT_NOTES_EDIT_FAILURE,
    payload,
  }),
};

export const deleteCreditNote = { // editCreditNote
  request: payload => ({
    type: Types.CREDIT_NOTES_DELETE_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CREDIT_NOTES_DELETE_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CREDIT_NOTES_DELETE_FAILURE,
    payload,
  }),
};

export const generateRegularCsv = {
  request: payload => ({
    type: Types.CREDIT_NOTES_GENERATE_REGULAR_CSV_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CREDIT_NOTES_GENERATE_REGULAR_CSV_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CREDIT_NOTES_GENERATE_REGULAR_CSV_FAILURE,
    payload,
  }),
};

export const generateXeroCsv = {
  request: payload => ({
    type: Types.CREDIT_NOTES_GENERATE_XERO_CSV_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CREDIT_NOTES_GENERATE_XERO_CSV_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CREDIT_NOTES_GENERATE_XERO_CSV_FAILURE,
    payload,
  }),
};

export const generateColppyCsv = {
  request: payload => ({
    type: Types.CREDIT_NOTES_GENERATE_COLPPY_CSV_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CREDIT_NOTES_GENERATE_COLPPY_CSV_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CREDIT_NOTES_GENERATE_COLPPY_CSV_FAILURE,
    payload,
  }),
};
