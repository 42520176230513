import { countriesReducer } from './countries.reducer';
import * as actions from './countries.actions';
import sagas from './countries.sagas';
import * as selector from './countries.selector';

export default {
  reducer: countriesReducer,
  actions,
  sagas,
  selector,
};
