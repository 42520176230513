// Types
export const Types = {
  ADJUSTMENTS_REQUEST: 'ADJUSTMENTS_REQUEST',
  ADJUSTMENTS_REQUEST_SUCCESS: 'ADJUSTMENTS_REQUEST_SUCCESS',
  ADJUSTMENTS_REQUEST_FAILURE: 'ADJUSTMENTS_REQUEST_FAILURE',
  CREATE_ADJUSTMENTS_REQUEST: 'CREATE_ADJUSTMENTS_REQUEST',
  CREATE_ADJUSTMENTS_SUCCESS: 'CREATE_ADJUSTMENTS_SUCCESS',
  CREATE_ADJUSTMENTS_FAILURE: 'CREATE_ADJUSTMENTS_FAILURE',
  CREATE_ADJUSTMENTS_RESET: 'CREATE_ADJUSTMENTS_RESET',

  EDIT_ADJUSTMENT_REQUEST: 'EDIT_ADJUSTMENT_REQUEST',
  EDIT_ADJUSTMENT_SUCCESS: 'EDIT_ADJUSTMENT_SUCCESS',
  EDIT_ADJUSTMENT_FAILURE: 'EDIT_ADJUSTMENT_FAILURE',
  EDIT_ADJUSTMENT_RESET: 'EDIT_ADJUSTMENT_RESET',

  DELETE_ADJUSTMENT_REQUEST: 'DELETE_ADJUSTMENT_REQUEST',
  DELETE_ADJUSTMENT_SUCCESS: 'DELETE_ADJUSTMENT_SUCCESS',
  DELETE_ADJUSTMENT_FAILURE: 'DELETE_ADJUSTMENT_FAILURE',
};

// Actions
export const adjustments = {
  request: payload => ({
    type: Types.ADJUSTMENTS_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.ADJUSTMENTS_REQUEST_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.ADJUSTMENTS_REQUEST_FAILURE,
    payload,
  }),
};

export const createAdjustments = {
  request: payload => ({
    type: Types.CREATE_ADJUSTMENTS_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.CREATE_ADJUSTMENTS_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.CREATE_ADJUSTMENTS_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.CREATE_ADJUSTMENTS_RESET,
  }),
};

export const editAdjustement = {
  request: payload => ({
    type: Types.EDIT_ADJUSTMENT_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.EDIT_ADJUSTMENT_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.EDIT_ADJUSTMENT_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.EDIT_ADJUSTMENT_RESET,
  }),
};

export const deleteAdjustment = {
  request: payload => ({
    type: Types.DELETE_ADJUSTMENT_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.DELETE_ADJUSTMENT_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.DELETE_ADJUSTMENT_FAILURE,
    payload,
  }),
};
