import { takeLatest, put, call } from 'redux-saga/effects';

// API
import { api } from 'services/api';

// Types
import { Types, fetchAllStates } from './states.actions';

const BASE_ENDPOINT = '/state';

const fetchList = async (countryId = '') => api.get(`${BASE_ENDPOINT}?country=${countryId}`);

function* fetchStatesList({ payload: countryId }) {
  let result;

  try {
    result = yield call(fetchList, countryId);

    if (result && result.data) {
      return yield put(fetchAllStates.success(result.data));
    }
    return yield put(fetchAllStates.failure('Unknown Error'));
  } catch ({ response: { data }, message }) {
    if (data) {
      const error = typeof data === 'string' ? data : data.code;
      return yield put(fetchAllStates.failure(error));
    }
    return yield put(fetchAllStates.failure(message));
  }
}

export default [
  takeLatest(Types.STATES_FETCH_ALL_STATES_REQUEST, fetchStatesList),
];
