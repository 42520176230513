import substract from 'date-fns/sub';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import format from 'date-fns/format';
import { formatToTimeZone } from 'date-fns-timezone';

import {
  invoiceStatus, emailTrackingStatus, paymentStatus, braInvoiceStatus,
} from 'store/invoice/invoice.enum';
import { trackingStatus } from 'store/creditNotes/creditNotes.enum';
import { countryCodes } from './countryCodes';

export const getKeys = object => {
  let output = [];
  if (object) output = Object.keys(object);
  return output;
};

export const isEmpty = (text = '') => !text || !text.trim();

export const validateEmail = email => {
  // eslint-disable-next-line max-len, no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(`${email}`.toLowerCase());
};

export const validateLegalEntityId = id => id >= 100000 && id <= 999999;

const EUCountries = [
  'Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France',
  'Germany', 'Greece', 'Hungary', 'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands',
  'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden',
];

export const belongsEU = countryName => EUCountries.includes(countryName);

export const validateNumber = value => {
  const output = value.match(/^-{0,1}\d+$/);
  return output;
};

export const generateQueryString = (baseUrl = '', queryData = {}) => {
  const filterParams = [];

  Object.entries(queryData).forEach(([filterKey, filterValue]) => {
    if (filterValue.length) {
      filterParams.push(`${filterKey}=${filterValue}`);
    }
  });

  if (filterParams.length > 0) {
    return `${baseUrl}?${filterParams.join('&')}`;
  }

  return baseUrl;
};

export const formatDate = date => {
  if (!date) return null;
  return formatToTimeZone(new Date(date), 'DD/MM/YYYY', { timeZone: 'UTC' });
};

export const formatPeriod = (date, separator = '-') => {
  const months = [0, 'Jan', 'Feb', 'Mar', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const m = parseInt(date.split('-')[1], 10);
  const year = date.split('-')[0];

  if (separator === ',') {
    const day = new Date(date).getDate();
    return `${months[m]} ${day}, ${year}`;
  }

  return `${months[m]} ${year}`;
};

export const formatMoney = (amount, currency, maximumFractionDigits = 2, amountType) => {
  const currencyFormat = {
    style: 'currency',
    currency,
    maximumFractionDigits,
  };

  const percentageFormat = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatType = amountType === 'PERCENTAGE' ? percentageFormat : currencyFormat;
  const totalAmount = amountType === 'PERCENTAGE' ? (amount / 100) : amount;
  return new Intl.NumberFormat(undefined, formatType).format(totalAmount || 0);
};

export const getLabelColor = (status, countryCode = countryCodes.us_uk) => {
  if (status === paymentStatus.overDue || status === braInvoiceStatus.void) return 'red';
  if (status === emailTrackingStatus.delivered
    || status === paymentStatus.partiallyPaid
     || status === trackingStatus.delivered) {
    return 'yellow';
  }
  if (status === paymentStatus.due) {
    if (countryCode === countryCodes.ar || countryCode === countryCodes.bra) {
      return 'blue';
    }
    return 'yellow';
  }
  if (status === invoiceStatus.billed
     || status === paymentStatus.paid
     || status === paymentStatus.paidCreditNote
     || status === trackingStatus.opened
     || status === emailTrackingStatus.opened) {
    return 'green';
  }

  return 'blue';
};

export const getPeriod = dateString => {
  const date = typeof dateString === 'string'
    ? new Date(dateString + (dateString.length === 10 ? 'T00:00:00' : ''))
    : dateString;
  return format(date, 'yyyy-MM-dd');
};

export const getFirstDayOfMonth = date => startOfMonth(date);

export const getLastDayOfMonth = date => lastDayOfMonth(typeof date === 'string' ? new Date(date) : date);

export const getDateInterval = (fromDate, toDate) => {
  if (fromDate === null && toDate === null) return [null, null];
  const from = getPeriod(fromDate);
  const to = getPeriod(toDate);

  return [from, to];
};

export const getDatesFromFilterOptions = goBackMonths => {
  const date = new Date();

  // This month
  if (goBackMonths === '0') {
    return [getFirstDayOfMonth(date), getLastDayOfMonth(date)];
  }

  // Last month
  if (goBackMonths === '1') {
    return [
      getFirstDayOfMonth(substract(date, { months: 1 })),
      getLastDayOfMonth(substract(date, { months: 1 })),
    ];
  }

  // Past months
  return [
    getFirstDayOfMonth(substract(date, { months: parseInt(goBackMonths, 10) })),
    getLastDayOfMonth(substract(date, { months: 1 })),
  ];
};

export const getPeriodFromBillDate = billDate => getFirstDayOfMonth(substract(new Date(billDate), { days: 5 }));

export const getFormattedPeriodFromBillDate = billDate => {
  const date = getFirstDayOfMonth(substract(new Date(billDate), { days: 5 }));
  const formattedPeriod = formatPeriod(getPeriod(date));
  return formattedPeriod;
};

export const getInvoiceNumber = invoice => {
  if (['US', 'UK'].includes(invoice.BillingOffice?.billingOffice)) {
    return invoice.invoiceId || '-';
  }
  if (invoice.internalId > 9999) {
    return invoice.internalId;
  }
  return `0000${invoice.internalId}`.substr(-5);
};
