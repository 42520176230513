export const Types = {
  PAYMENT_SETTING_FETCH_ALL_REQUEST: 'PAYMENT_SETTING_FETCH_ALL_REQUEST',
  PAYMENT_SETTING_FETCH_ALL_SUCCESS: 'PAYMENT_SETTING_FETCH_ALL_SUCCESS',
  PAYMENT_SETTING_FETCH_ALL_FAILURE: 'PAYMENT_SETTING_FETCH_ALL_FAILURE',
  PAYMENT_SETTING_CREATE_PAYMENT_SETTING_REQUEST: 'PAYMENT_SETTING_CREATE_PAYMENT_SETTING_REQUEST',
  PAYMENT_SETTING_CREATE_PAYMENT_SETTING_SUCCESS: 'PAYMENT_SETTING_CREATE_PAYMENT_SETTING_SUCCESS',
  PAYMENT_SETTING_CREATE_PAYMENT_SETTING_FAILURE: 'PAYMENT_SETTING_CREATE_PAYMENT_SETTING_FAILURE',
  PAYMENT_SETTING_CREATE_PAYMENT_SETTING_RESET: 'PAYMENT_SETTING_CREATE_PAYMENT_SETTING_RESET',
};

// Actions
export const fetchAllPaymentSettings = {
  request: payload => ({
    type: Types.PAYMENT_SETTING_FETCH_ALL_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.PAYMENT_SETTING_FETCH_ALL_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.PAYMENT_SETTING_FETCH_ALL_FAILURE,
    payload,
  }),
};

export const createPaymentSettings = {
  request: payload => ({
    type: Types.PAYMENT_SETTING_CREATE_PAYMENT_SETTING_REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.PAYMENT_SETTING_CREATE_PAYMENT_SETTING_SUCCESS,
    payload,
  }),
  failure: payload => ({
    type: Types.PAYMENT_SETTING_CREATE_PAYMENT_SETTING_FAILURE,
    payload,
  }),
  reset: () => ({
    type: Types.PAYMENT_SETTING_CREATE_PAYMENT_SETTING_RESET,
  }),
};
