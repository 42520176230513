import { Types } from './user.actions';

export const INITIAL_STATE = {
  loading: null,
  current: null,
  error: null,
};

export function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CURRENT_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.CURRENT_USER_REQUEST_SUCCESS:
      return { ...state, loading: false, current: action.payload };
    case Types.CURRENT_USER_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
